import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import 'typeface-roboto';
import { TextField, Button, Icon, ListItemIcon, CircularProgress } from "@material-ui/core";
import { QuestionAnswer } from '@material-ui/icons';
import { apihost, bbc, AppEvent } from '../../Helper';
import Axios from 'axios';
import { Virtuoso } from 'react-virtuoso';
import { GlobalState } from '../../AppState';
import { observer } from 'mobx-react';
import men from './../../assets/men.png';
import { Types } from 'mongoose';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            paddingTop: 20,
            paddingBottom: 20,
            maxHeight: '500px',
            //   Height: 600,
            backgroundColor: theme.palette.background.paper,
        },
        noticeStyle: {
            backgroundColor: '#efefef',
        },
        button: {
            marginTop: -10,
        },
        inline: {
            display: 'inline',
        },





        msgBottomText: {

            marginLeft: 20,

            fontSize: 10,
            color: '#efefef',
        },

        msgNameText: {
            color: '#efefef',
            fontSize: 14,

            marginLeft: 70,

        },

        msgBox: {
            paddingTop: 10,
            paddingBottom: 10,
        },

        msgBoxBackground: {

            marginTop: 200,
            height: 600,
            backgroundImage: 'url(" + { msgbackground } + ")',

        },
        iconStyle: {

            fontSize: 80,
            color: '#efefef',


        },

        startingText: {

            color: '#b5afaf',
        },
        msgTextRight: {

            fontSize: 10,
            borderTopRightRadius: 7,
            borderTopLeftRadius: 35,
            borderBottomRightRadius: 35,
            borderBottomLeftRadius: 35,
            padding: 15,
            marginBottom: -10,
            marginTop: -10,
            backgroundColor: '#3F51B5',
            color: '#ffffff',
            overflow: 'hidden',
            wordBreak: 'break-all',
            display: 'block',
            opacity: '80%',

        },

        msgText: {

            fontSize: 10,
            borderTopRightRadius: 35,
            borderTopLeftRadius: 7,
            borderBottomRightRadius: 35,
            borderBottomLeftRadius: 35,
            padding: 15,
            marginBottom: -10,
            marginTop: -10,
            
            backgroundColor: 'grey',
            color: 'black',
            overflow: 'hidden',
            wordBreak: 'break-all',
            display: 'block',
            opacity: '80%',

        },
    }),
);

export const MessageRow: FunctionComponent<{ message: any }> = props => {

    const [isSending, setSending] = useState(false);
    var x = props.message;
    var classes = useStyles({});
    var date = new Date(Types.ObjectId(props.message['id']).generationTime * 1000);


    useEffect(() => {
        if (props.message['unsent'] == true) {
            setSending(true);
            Axios.post(apihost + '/sendToCandidate', { messageText: props.message['text'] }, { headers: { employer: props.message['chat']['employerEmail'], candidate: props.message['chat']['candidateEmail'] } }).then(x => {
                setSending(false);
            })


        }
    }, [])
    let isMe = globalChatForAll['employer'] == props.message['from'];

    return <div className={classes.msgBox}>
        <ListItem alignItems="flex-start" >
            <ListItemText
                secondary={
                    <React.Fragment>
                        <Typography
                            className={classes.msgNameText}
                            component="span"
                            variant="body2"
                            color="textPrimary"
                            style={{ color: '#726d6d' }}
                        >
                            {/* {JSON.stringify(props.message)} */}
                        </Typography>
                        <Typography variant="caption" style={{ color: '#000000' }} className={classes.msgBottomText}>{date.toDateString() + ' ' + date.toLocaleTimeString()}</Typography>
                    </React.Fragment>
                }
            />
        </ListItem>
        <ListItem alignItems="flex-start"  >
            {isSending && <ListItemIcon>
                <CircularProgress />
            </ListItemIcon>}
            {!isMe && <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={men} />
            </ListItemAvatar>}
            <ListItemText
                className={isMe ? classes.msgTextRight : classes.msgText}
                primary={<div>
                    {x['text']}
                </div>}
            />

            {isMe && <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={men} />
            </ListItemAvatar>}
        </ListItem>
    </div>

};
let globalChatForAll;

export const Messages = observer(() => {


    const [texts, setTexts] = useState<{ from: string, to: string, text: string }[]>([]);
    const [selectedChat, setSelectedChat] = useState(GlobalState.currentChat);
    const [currentText, setCurrentText] = useState('');

    let d = texts;
    const virt: React.RefObject<Virtuoso> = useRef(null);
    useEffect(() => {

        bbc.on(AppEvent.newChatSelected, (newChat) => {
            setSelectedChat(newChat);
            GlobalState.messages = [];
            globalChatForAll = newChat;
            Axios.get(apihost + '/getmessages', { headers: { chat: newChat['_id'] } }).then(x => {
                var list: any[] = x.data;
                var data = list.map(c => {
                    return { id: c['_id'], from: c['sender'], to: c['reciever'], text: c['text'], chat: newChat['_id'] }
                });
                GlobalState.messages = data;
                setTexts(data);
            })
        })

        bbc.on(AppEvent.newMessageRecieved, (msg) => {
            console.log(msg);
            GlobalState.messages.push({ text: msg['notification']['body'], from: msg['data']['sender'], to: msg['data']['reciever'] });

             new Promise((resolve) => setTimeout(resolve, 100)).then(x=>{
                scrollToBottom();
             })
           
        })
    }, []);
    const scrollToBottom = () => {
        if (virt != null) {
            if (virt.current != null) {
                console.log('scrolling to : ' + GlobalState.messages.length)
                virt.current.scrollToIndex(GlobalState.messages.length);
                virt.current.scrollToIndex(GlobalState.messages.length + 200);

            }
        }
    }
    const classes = useStyles({});
    if (selectedChat == null) {
        return <div className={classes.msgBoxBackground}>
            <QuestionAnswer className={classes.iconStyle}></QuestionAnswer><br />
            <Typography variant="caption" className={classes.startingText}>Select A Contact To Start Conversation!..</Typography></div>
    }
    return (<div>
        <Virtuoso ref={virt} style={{ width: '100%', height: '480px' }} totalCount={GlobalState.messages.length} item={index => <div><MessageRow message={GlobalState.messages[index]} /></div>} />
        <ListItem alignItems="flex-start">
            <TextField value={currentText} variant="outlined" fullWidth label="Type your message here..."
                onChange={e => {
                    setCurrentText(e.target.value);
                }}

                onKeyDown={async k => {
                    if (k.keyCode == 13) {
                        /**
                      Axios.post(apihost + '/sendToCandidate', { messageText: currentText }, { headers: { employer: 'mail', candidate: selectedChat['candidateEmail'] } })
   */
                        await new Promise((resolve) => setTimeout(resolve, 10));

                        console.log('old length : ' + GlobalState.messages.length);
                        GlobalState.messages.push({ from: selectedChat['employer'], to: selectedChat['candidate'], text: currentText, unsent: true, chat: selectedChat });
                        console.log('New length : ' + GlobalState.messages.length);

                        setCurrentText('');

                        scrollToBottom();
                    }
                }}
            >
            </TextField><br />
        </ListItem>
        <ListItem alignItems="flex-start">
            <Button
                variant="contained"
                color="primary"
                onClick={async () => {

                    /**
                    Axios.post(apihost + '/sendToCandidate', { messageText: currentText }, { headers: { employer: 'mail', candidate: selectedChat['candidateEmail'] } })
 */
                    await new Promise((resolve) => setTimeout(resolve, 10));

                    console.log('old length : ' + GlobalState.messages.length);
                    GlobalState.messages.push({ from: selectedChat['employer'], to: selectedChat['candidate'], text: currentText, unsent: true, chat: selectedChat });
                    console.log('New length : ' + GlobalState.messages.length);

                    setCurrentText('');

                    scrollToBottom();
                }}
                className={classes.button}
                endIcon={<Icon>send</Icon>}
            >
                Send
      </Button>
        </ListItem>
    </div>);


}) 