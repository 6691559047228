import React, { useState, FunctionComponent, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Grid, Typography } from '@material-ui/core';
import { Lock, Work, Email, Person } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import employer from './../../assets/employer.png';
import Axios from 'axios';
import { apihost, API } from '../../Helper';
import { read_cookie } from 'sfcookies'
import { TextValidator, ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';





const useStyles = makeStyles((theme) => ({

    formStyle: {
        // backgroundImage: 'url(https://source.unsplash.com/random)',

        //   background: 'linear-gradient(to bottom, rgba(87, 83, 236) 100%, rgba(123, 84, 228) )',
        backgroundColor: '#211F2C',
        marginTop: '2%',
        height: 50,
    },

    header: {
        backgroundColor: '#211F2C',
        // background: 'linear-gradient(to bottom, rgba(87, 83, 236) 100%, rgba(123, 84, 228) )',
        color: '#ffffff',
        paddingLeft: '5%',
    },

    vectorStyle: {
        marginTop: '25%',
        maxWidth: 420,
        maxHeight: 240,
    },

    btnGrid: {
        marginLeft: '10%',
    },

    saveButton: {
        padding: '3%',
        backgroundColor: '#211F2C',
        color: '#ffffff',
        width: 120,
        height: 45,
        '&:hover': {
            backgroundColor: '#2e2b3f',
            color: '#ffffff',
        }
    },

    btnStyle: {
        backgroundColor: '#ffffff',
        color: '#7b54e4',
        '&:hover': {
            backgroundColor: '#efefef',
            color: '#7b54e4',

        }

    },
    formControl: {
        margin: theme.spacing(1),

        width: '100%',
        marginTop: 10,
    },


}));




export const PopupForm: FunctionComponent<{ member?: {}, onClose?: () => void, isOpen: boolean }> = props => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (props.onClose != null) {
            props.onClose();
        }
    };


    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [role, setRole] = useState()

    useEffect(() => {

        if (props.member != null) {
            setName(props.member['name']);
            setRole(props.member['role']);
            setEmail(props.member['email']);
            setPassword(props.member['password']);
            setOpen(true);
        }
        if (props.isOpen) {
            setOpen(true);
        }
    }, [])


    return (
        <div>
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen} >
                {props.member != {} && 'Edit Member'}
                {props.member == {} && 'Edit Member'}
            </Button> */}



            <Dialog open={open && props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg" >
                <DialogTitle id="form-dialog-title" className={classes.header}>

                    {props.member == null && 'Add Member'}
                    {props.member != null && 'Edit Member'}
                </DialogTitle>

                <DialogContent style={{ paddingLeft: '10%', paddingRight: '10%', paddingTop: '20%', paddingBottom: '10%', }}>
                    <ValidatorForm
                        onSubmit={async r => {

                            var member = {
                                name: name,
                                email: email,
                                password: password,
                                role: role

                            }

                            var status: number = 0;
                            if (props.member != null) {
                                var res = await Axios.post(apihost + '/employer/update', member);
                                status = res.status;
                            } else {
                                var res = await Axios.post(apihost + '/employer/addmember', member, { headers: { employer: read_cookie('data')['id'] } });
                                status = res.status;

                            }

                            if (status == 200) {
                                setOpen(false);
                                if (props.onClose != null) {
                                    props.onClose();
                                }
                            }
                            window.location.reload();
                        }}
                    >
                        <Grid container>
                            <Grid item lg={6}>

                                <FormControl className={classes.formControl}>
                                    <Grid container>
                                        <DialogContentText>
                                            <Typography variant="body2">To Add a new member, Please complete the following form. </Typography>

                                        </DialogContentText>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <Person style={{ color: '#9b9a9e', fontSize: 20, }} />
                                            </Grid>
                                            <Grid item xs>
                                                <TextValidator
                                                    value={name}
                                                    onChange={val => setName(val.target['value'])}
                                                    id="input-with-icon-grid"
                                                    fullWidth label="Name"
                                                    name='name'
                                                    required

                                                    validators={['required', 'matchRegexp:^[a-zA-Z]+$']}
                                                    errorMessages={['This Field is Required', 'Name Can only Contain letters']}
                                                    // validators={['required', 'matchRegexp:^[a-zA-Z]']}
                                                    // errorMessages={['this field is required', 'Name Cannot contain Numbers']}
                                                    style={{ marginLeft: '2%' }} />
                                            </Grid>
                                        </Grid><br />


                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <Email style={{ color: '#9b9a9e', fontSize: 20, }} />
                                            </Grid>
                                            <Grid item xs>
                                                <TextValidator
                                                    id="input-with-icon-grid"
                                                    value={email}
                                                    onChange={val => setEmail(val.target['value'])}
                                                    fullWidth
                                                    name='email'
                                                    label="Email"
                                                    type="email"
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={['This Field is Required', 'Invalid Email']}

                                                    style={{ marginLeft: '2%' }} />
                                            </Grid>
                                        </Grid>
                                        <br />

                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <Lock style={{ color: '#9b9a9e', fontSize: 20, }} />
                                            </Grid>
                                            <Grid item xs>
                                                <TextValidator
                                                    id="input-with-icon-grid"
                                                    fullWidth
                                                    value={password}
                                                    onChange={val => setPassword(val.target['value'])}
                                                    label="Password"
                                                    type="password"
                                                    name='password'
                                                    required
                                                    validators={['required' , 'minStringLength:6']}
                                                    errorMessages={['This Field is Required' , 'Password must be at least 6 characters long']}
                                                    style={{ marginLeft: '2%' }} />
                                            </Grid>
                                        </Grid>


                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <Person style={{ color: '#9b9a9e', fontSize: 20, }} />
                                            </Grid>
                                            <Grid item xs >
                                                {/* <InputLabel id="demo-simple-select-label">Role</InputLabel> */}
                                                <SelectValidator
                                                    label="Role"
                                                    id="demo-simple-select"
                                                    value={role}
                                                    onChange={val =>
                                                        setRole(val.target['value'])
                                                    }
                                                    name='role'
                                                    withRequiredValidator

                                                    validators={['required']}
                                                    errorMessages={['This Field is Required']}
                                                    // InputProps={{ startAdornment: <Work style={{ color: '#9b9a9e', fontSize: 20, }} /> ,  }}
                                                    fullWidth
                                                >
                                                    <MenuItem value={'director'}>Director</MenuItem>
                                                    <MenuItem value={'manager'}>Manager</MenuItem>
                                                    <MenuItem value={'assistant'}>Assistant</MenuItem>
                                                    <MenuItem value={'developer'}>Developer</MenuItem>
                                                </SelectValidator>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} >

                                <img src={employer} alt="vector image" className={classes.vectorStyle} />
                            </Grid>

                            <Grid item lg={3} className={classes.btnGrid}>
                                <Button type='submit' onClick={async () => {
                                }} color="primary" variant="outlined" size="large" className={classes.saveButton}
                                >
                                    <Typography variant="body2">Save</Typography>

                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </DialogContent>







                <DialogActions className={classes.formStyle}>
                    {/* <Button onClick={handleClose} color="primary" variant="outlined" className={classes.btnStyle}>
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" variant="outlined" className={classes.btnStyle}>
            Save
          </Button> */}
                </DialogActions>
            </Dialog>





        </div >
    );
}