import { Hidden } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AppsIcon from '@material-ui/icons/Apps';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { FunctionComponent } from 'react';
import flag from './../assets/flag.jpg';
import men from './../assets/men.png';
import { createBrowserHistory } from 'history';
import { Ways } from '../Helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      boxShadow: 'none',
      position: 'static',

   

    },

    accountImage: {
      maxWidth: 24,
      maxHeight: 24,
    },

    accountText: {
color: '#454545',
marginRight: 10,

    },

    accountTextFirst: {
      color: '#7f7b7b',
      marginRight: 3,
      
    },

    topIcons: {
      color: '#454545',
      paddingLeft: 12,
      paddingRight: 12,

         
        
      


     
    },

    toolbarIcons: {

      '& .MuiIconButton-root': {
       
        '&:hover':{
          backgroundColor: '#F7F7F7',
        },
      },


    },

    headerTop: {
      backgroundColor: '#F7F7F7',
      boxShadow: 'none',


     
      
    },

    menuButton: {
      marginRight: theme.spacing(2),
      color: '#454545',

    },
    title: {
      flexGrow: 1,
      boxShadow: 'none',
    },

    topBar: {

      boxShadow: 'none',

    },
  }),
);

export const TitleBar: FunctionComponent<{ onMenuClick?: () => void , onOpenClick?:()=>void }> = props => {
  const classes = useStyles({});
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root} >
   
      <AppBar position="static" className={classes.topBar} >
        <Toolbar className={classes.headerTop}>
        
          <Typography variant="h6" className={classes.title}>
           <Hidden smUp>
           <IconButton
              color="inherit"
              
              aria-label="open drawer"
              edge="start"
              onClick={x => {
                if(props.onOpenClick!=null){
                  props.onOpenClick();

                }
              }}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
           </Hidden>
          </Typography>

          {auth && (
            <div className={classes.toolbarIcons} >
            

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                size="small"
                onClick={()=>{
                  createBrowserHistory().push(Ways.employerAccountPage);
                  window.location.reload();
                }}
                color="inherit"
                className={classes.topIcons}
              >
                <SettingsIcon />
              </IconButton>

              <IconButton>
               <img src={flag} alt="placeholder image" className={classes.accountImage} />
               </IconButton>
              <IconButton>
                <Typography variant="caption" className={classes.accountTextFirst}>Hi, </Typography>
                <Typography variant="caption" className={classes.accountText}>Sean</Typography>
               <img src={men} alt="placeholder image" className={classes.accountImage} />
               </IconButton>
{/* 
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton> */}
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
     
     
    </div>
  );
}