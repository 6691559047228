import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, fade, Theme, makeStyles } from '@material-ui/core/styles';
import { Paper , Button, Container, Grid} from '@material-ui/core';
import { WorkOutline } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { Chat } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      boxShadow: 'none',
      backgroundColor: '#002448',
      paddingTop: 80,
      paddingBottom: 40,
      zIndex: 20,
      width: '100%',
     
      
      
      
    },
    mainContainer: {
      backgroundColor: '#211F2C',
    },

    topBar: {

        backgroundColor: '#002448',
        boxShadow: 'none',
        paddingTop: 90,
        marginTop: -90,
        alignItems: 'stretch',
       
    },

    btnStyle: {

        color: '#ffffff',
        borderColor: '#efefef',

        '&:hover': {

          color: '#efefef',
        borderColor: '#efefef',
       
         
         
        },
    },

    iconStyle: {
      fontSize: 35,

    },

    


    menuButton: {
      marginRight: theme.spacing(2),
     
    },
    title: {
      flexGrow: 1,
    //   display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
   
  }),
);

export const EmployerTitleBar = () => {
  const classes = useStyles();

  return (
  
  
  <Container maxWidth="xl"  style={{backgroundColor:'#002448', height:240, marginTop: -50,}} >
   
  </Container>
  
  );
}