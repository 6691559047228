import { Container } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      boxShadow: 'none',
      backgroundColor: '#002448',
      paddingTop: 80,
      paddingBottom: 40,
      zIndex: 20,
     
      
      
      
    },
    mainContainer: {
      backgroundColor: '#211F2C',
    },

    topBar: {

        backgroundColor: '#002448',
        boxShadow: 'none',
        paddingTop: 90,
        marginTop: -90,
        alignItems: 'stretch',
       
    },

    btnStyle: {

        color: '#ffffff',
        borderColor: '#efefef',

        '&:hover': {

          color: '#efefef',
        borderColor: '#efefef',
       
         
         
        },
    },

    iconStyle: {
      fontSize: 35,

    },


    menuButton: {
      marginRight: theme.spacing(2),
     
    },
    title: {
      flexGrow: 1,
    //   display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
   
  }),
);

export const SingleJobCandidatesTitleBar = () => {
  const classes = useStyles();

  return (
  
  
  <Container maxWidth="xl" style={{backgroundColor:'#002448', height:240, marginTop: -40,}}>

   

     
   
  </Container>
  
  );
}