import React, { FunctionComponent } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DraftsIcon from '@material-ui/icons/Drafts';
import ShareIcon from '@material-ui/icons/Share';
import PhoneIcon from '@material-ui/icons/Phone';
import 'typeface-roboto';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import { getvalue } from '../../Helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      //   marginTop: 300,
      borderRadius: 15,
      paddingBottom: 20,
      paddingTop: 20,
    },


    iconStyle: {

      color: '#b2aeae',

    },

    icons: {

      marginLeft: -50,
    },

    iconText: {

      fontSize: 10,


    },
    headings: {
      color: '#797980',
    },

  }),
);


export const ListIconComponent: FunctionComponent<{ profile?: {} }> = props => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Container>
        <Grid container direction="row" >
          <Grid item lg={12}>
            <Typography variant="h6" className={classes.headings} noWrap>
              Contact <br />
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <List className={classes.iconText} component="nav" aria-label="main mailbox folders">
        <ListItem button>
          <ListItemIcon className={classes.iconStyle}>
            <DraftsIcon className={classes.icons} />
          </ListItemIcon>
          <Typography variant="body2" noWrap>
            Email<br /> <div style={{ color: '#5a93be' }}>{getvalue(props.profile , 'employer.email') ?? '' }</div>
          </Typography>

          {/* <ListItemText primary="Email: kamranasif520@gmail.com" /> */}
        </ListItem>



        <ListItem button>
          <ListItemIcon className={classes.iconStyle}>
            <ShareIcon className={classes.icons} />
          </ListItemIcon>
          <Typography variant="body2" noWrap>
            Web Page<br /> <div style={{ color: '#5a93be' }}>www.google.com</div>
          </Typography>
          {/* <ListItemText primary="Web Page : www.google.com" /> */}
        </ListItem>

        <ListItem button>
          <ListItemIcon className={classes.iconStyle}>
            <PhoneIcon className={classes.icons} />
          </ListItemIcon>
          <Typography variant="body2" noWrap>
            Phone<br /><div style={{ color: '#5a93be' }}> 0346-2323-4534</div>
          </Typography>
          {/* <ListItemText primary="Phone: 03400-3454-347" /> */}
        </ListItem>



      </List>


    </div>
  );
}