import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Mail, Settings, LibraryAdd, AccountBox, Group, Work, ExitToApp } from '@material-ui/icons';
import 'typeface-roboto';
import { Typography, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Ways, logOut } from '../Helper';


const useStyles = makeStyles({

  iconStyle: {
    width: '100%',
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    transition: 'background-color 0ms',
    '&.makeStyles-iconStyle-176 .MuiListItem-button': {
      display: 'block',
      paddingLeft: '10%',
      textAlign: 'center',
    },
    '&.MuiListItemIcon-root': {
      paddingLeft: 16,
    },
    '& .MuiListItem-button': {
      transitionDuration: '0ms',
      transitionDelay: '0ms',
      '&:hover': {
        backgroundColor: '#131219',
      },
    },
  },
  icontext: {
    '&:hover': {
    },
  },
  sidebarLinks: {
    textColor: '#efefef',
    '&:hover': {
      color: '#ffffff',
      listStyle: 'none',
    }
  },
  linkStyle: {
    textDecoration: 'none',
    color: '#efefef',
  },
  sidebarIcons: {
    color: '#9a9a9e',
    '&:hover': {
      color: '#ffffff',
    }
  },
});



export const MenuBarItems: { path: string, title: string, icon: JSX.Element, onClick?: () => void }[] = [

  { path: Ways.employerDashBoard, icon: <Settings style={{ color: '#ffffff', }} />, title: "Dashboard" },
  { path: Ways.employerJobs, icon: <Badge badgeContent={99} color="secondary"> <Work style={{ color: '#ffffff' }} /> </Badge>, title: "Jobs" },
  { path: Ways.employerCandidates, icon: <Badge badgeContent={56} color="primary"> <Group style={{ color: '#ffffff' }} /></Badge>, title: "Candidates" },
  { path: Ways.employerAllChats, icon: <Badge badgeContent={6} color="secondary"> <Mail style={{ color: '#ffffff' }} /> </Badge>, title: "Message" },


  { path: Ways.postaJob, icon: <LibraryAdd style={{ color: '#ffffff' }} />, title: "Post a job" },
  { path: Ways.employerAccountPage, icon: <AccountBox style={{ color: '#ffffff' }} />, title: "Account" },
  
  { title: 'Logout' , onClick: ()=>{
    logOut();
  }, path: '/', icon: <ExitToApp style={{ color: '#fff' }} /> },

]
export const SidebarMenu: FunctionComponent = (props) => {
  const classes = useStyles(props);

  let path = window.location.pathname;


  return <div className={classes.iconStyle}>
    {MenuBarItems.map((x) => {
      let isSelected = path == x.path;

      if (x.onClick != null) {
        return <ListItem button onClick={() => {

          if (x.onClick != null) {
            x.onClick();
          }
        }} style={isSelected ? {
          backgroundColor: '#946fe0',
          marginLeft: 2,
        } : {}}>
          <ListItemIcon style={{ marginRight: '4%' }}>
            {x.icon}

          </ListItemIcon>

          <ListItemText primary={x.title} >
          </ListItemText>
        </ListItem>
      }
      return <NavLink to={x.path}
        style={{ textDecoration: 'none', color: path == x.path ? 'white' : '#ffffff' }}>
        <ListItem button onClick={() => {

          if (x.onClick != null) {
            x.onClick();
          }
        }} style={isSelected ? {
          backgroundColor: '#3d3b49',
        } : {}}>
          <ListItemIcon>
            {x.icon}
          </ListItemIcon>
          <ListItemText  >
            <Typography align="center" variant="body2">
              {x.title}
            </Typography>
          </ListItemText>
        </ListItem></NavLink>
    })}

  </div>
}