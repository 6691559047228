import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Grid, Box, Container } from '@material-ui/core';
// import { Profile } from "./../Chatroom/Profile";
import { TabsComponent } from './TabsComponent';
import { ListIconComponent } from './ListIconComponent';
import { StickyHeader } from './StickyHeader';
import { Members } from './Members';
import { PhotoGallery } from './PhotoGallery';
import { EventCard } from './EventCard';
import { Biography } from './Biography';
import { LogoHeader } from './LogoHeader';
import { AllMembers } from '../Employer/AllMembers';
import Axios from 'axios';
import { API, apihost } from '../../Helper';
import { read_cookie } from 'sfcookies';
import { StartACampaign } from './StartACampaign';
import { EmployerAllJobsPage, EmployerJobsTable } from '../EmployerJobs';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: -50,


    },

    secondContainer: {


      paddingLeft: 50,
      paddingRight: 50,

    },

    eventsGrid: {
      marginTop: -8,

      [theme.breakpoints.up('xs')]: {
        marginBottom: 90,
      },
    },

    membersGrid: {
      marginTop: -70,
      [theme.breakpoints.up('xl')]: {
        marginTop: -20,
      },

      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
      },

    },

    photosGrid: {
      marginTop: -50,

      [theme.breakpoints.down('sm')]: {
        marginTop: -320,
      },


    },

    biographyGrid: {
      marginBottom: 30,
      marginTop: 30,
      
    },





  }),
);

export const MyAccountLayout = () => {
  const classes = useStyles({});
  const [accountState, setAccountState] = useState({ profile: {}, members: null });


  const getData = async () => {
    let xr = await Axios.get(apihost + '/employer/members', { headers: { employer: read_cookie('data')['id'] } });
    let profile = await Axios.get(apihost + '/employer/getprofile', { headers: { employer: API.getCookieData()['id'] } });
    setAccountState({
      members: xr.data,
      profile: profile.data
    })
    console.log({
      members: xr.data,
      profile: profile.data
    })
  }


  useEffect(() => {
    getData();
  }, []);

  return (
    <Box >
      <Container maxWidth="xl" className={classes.root}>
        <Grid container >
          <Grid item lg={12} xs={12} sm={12} md={12}>
            {/* <StickyHeader /> */}
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" className={classes.secondContainer}>

        <Grid container spacing={2} data-aos="fade-up" data-aos-duration="1500">

          <Grid item lg={8} xs={12} sm={12} md={12}>
            <TabsComponent profile={accountState.profile} members={accountState.members} />
          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={12}>
            <ListIconComponent profile={accountState.profile} />
          </Grid>
        </Grid>



       


      

        <Grid container spacing={2} >
          <Grid item lg={8} xs={12} sm={12} md={12} className={classes.biographyGrid} data-aos="fade-up" data-aos-duration="1500">
            <Biography profile={accountState.profile} />

          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={12} className={classes.membersGrid} data-aos="fade-up" data-aos-duration="1500">
            <Members members={accountState.members} />
          </Grid>

        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={8} xs={12}>
            <EmployerJobsTable />

          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={11} className={classes.photosGrid}>
            <PhotoGallery />
          </Grid>

        </Grid>

        <Grid container spacing={2} data-aos="fade-up" data-aos-duration="1500">
          <Grid item lg={8} xs={12} sm={12} md={12} className={classes.eventsGrid}>
            <AllMembers />
          </Grid>
       

        </Grid>



      </Container>





    </Box>



  );
}