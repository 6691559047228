import { Button, Container, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { red } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import 'typeface-roboto';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '100%',
            flexDirection: 'row',

            paddingTop: 10,
            paddingBottom: 50,
            backgroundColor: '#ffffff',

        },

        summaryContainer: {


            backgroundColor: '#ffffff',
            paddingBottom: 30,
        },

        portfolioImg: {

            width: 200,
            height: 220,
        },



        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },


        btnLanguages: {

            display: 'flex',
            flexDirection: 'row',

        },


        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },


        actionIconsStyle: {

            backgroundColor: '#e2e0e0',
        },

        mainCard: {

            paddingTop: 50,
            paddingBottom: 30,

        },

        avatar: {
            backgroundColor: red[500],
        },
    }),
);

export const CandidateSkills: FunctionComponent<{ userData: {} }> = props => {
    const classes = useStyles({})
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (

        <Card className={classes.mainCard} data-aos="fade-up" data-aos-duration="1500">
            <Container maxWidth="lg" className={classes.summaryContainer}>
                <Grid container spacing={2} >

                    {/* First Card */}



                    <Grid item lg={12} xs={12}>
                        <Typography variant="h6" align="left" gutterBottom>
                            Candidate Skills
          </Typography>
                    </Grid>

                    {props.userData['cv']['skills']!=null && props.userData['cv']['skills'].map(skill=>{
                        return <Grid item lg={2} xs={12}>
                            <Button variant="outlined" size="small" disabled>
                                {skill}
                                    </Button>
                        </Grid>;
    
                    })}

                 
                </Grid>
            </Container>
        </Card>



    );
}