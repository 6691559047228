import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { AppBar, Toolbar, Box, Link} from '@material-ui/core';
import { EmployerTitleBar } from './EmployerTitleBar';
import { Group, Work, AccountBox, RecentActors } from '@material-ui/icons';
import reptile from './reptile.jpg';
import hr from './hr.png';
import jobs from './jobs.png';
import contacts from './contacts.png'
import accept from './accept.png';
import jobsnew from './../../assets/jobsnew.png';
import interview from './../../assets/interview.png';
import card from './../../assets/card.png';
import candidatesnew from './../../assets/candidatesnew.png';
import plus from './../../assets/plus.png';
import 'typeface-roboto';
import { camelize, API, apihost } from '../../Helper';
import Axios from 'axios';






const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  card: {
    // backgroundColor: '#CCAC00',
    background:
      'linear-gradient(to right, rgba(65, 209, 140), rgba(11, 194, 111) 70%)',

    color: '#ffffff',
    display: 'flex',
    padding: 3,
    backgroundPosition: 'right',
    minHeight: 150,

  },

  card2: {
    background:
      'linear-gradient(to right, rgba(254, 207, 67), rgba(254, 193, 7) 70%)',
    color: '#ffffff',
    display: 'flex',
    padding: 3,
    minHeight: 150,
  },

  card3: {

    background:
      'linear-gradient(to right, rgba(108, 121, 199), rgba(63, 81, 181) 70%)',
    color: '#ffffff',
    display: 'flex',
    padding: 3,
    minHeight: 150,
  },


  card4: {

    background:
      'linear-gradient(to right, rgba(251, 175, 152), rgba(247, 140, 111) 70%)',
    color: '#ffffff',
    display: 'flex',
    padding: 3,
    minHeight: 150,
  },




  iconStyle: {
    color: '#ffffff',
    opacity: '80%',
    fontSize: '100',

  },

  headingStyle: {

    color: '#ffffff',
    fontSize: 40,

  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
  },


  MuiSvgIconFontSizeLarge: {
    fontSize: 5.1875,
  },


  title: {
    fontSize: 14,
    color: '#ffffff',
    opacity: '90%',
  },


  media: {

    position: 'absolute',


    marginLeft: 25,
    maxWidth: 286,
    maxHeight: 170,
    
    

  },

  mediaLast: {
      
      position: 'absolute',

  },


  cardLast: {

    background:
    'linear-gradient(to right, rgba(239, 237, 237), rgba(214, 212, 212) 60%)',

  color: '#ffffff',
  display: 'flex',
  padding: 3,
  backgroundPosition: 'right',
  minHeight: 150,
  },

  topBar: {

    backgroundColor: '#002448',
    boxShadow: 'none',
    // paddingTop: 90,
    marginTop: -80,
  
    alignItems: 'stretch',
   
},

headtitle: {
  flexGrow: 1,
//   display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  }
},


}),
);

export const LayoutCards = () => {
  const classes = useStyles();

  const [data , setData] = useState({});


  const getData = async ()=>{
    let data = await Axios.get(apihost + '/employer/overview' , {headers:{employer : API.getCookieData()['id']}});
    console.log(data.data);
    setData(data.data);

  }
  useEffect(()=>{
    getData();
  }, []);

  return ( <Box>
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={2}
      
    >
<Grid item lg={12} xs={12} md={12} data-aos="fade-up" data-aos-duration="1500">
<AppBar position="static" className={classes.topBar}>
    <Toolbar>
        {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
        <Chat className={classes.iconStyle} />
      </IconButton> */}
      <Typography variant="h5" className={classes.headtitle}>
        {camelize('welcome mark anthony')}
      </Typography>
      {/* <Button variant="outlined" className={classes.btnStyle}>Add Item</Button> */}
    </Toolbar>
  </AppBar> 
</Grid>



      <Grid item xs>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              component="img"
              alt="Contemplative Reptile"

              src={candidatesnew}
              title=""
            />
            <CardContent>
              <Typography className={classes.headingStyle} variant="h3" color="primary" gutterBottom>
                {data['views']}
        </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                CANDIDATES REACHED
        </Typography>

            </CardContent>
          </CardActionArea>
         
        </Card>
      </Grid>




      <Grid item xs>
        <Card className={classes.card2}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              component="img"
              alt="Contemplative Reptile"

              src={jobsnew}
              title=""
            />
            <CardContent>
              <Typography className={classes.headingStyle} variant="h3" color="primary" gutterBottom>
                {data['jobs']}
        </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                JOBS POSTED
        </Typography>


            </CardContent>
          </CardActionArea>
       
        </Card>
      </Grid>





      <Grid item xs>
        <Card className={classes.card3}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              component="img"
              alt="Contemplative Reptile"

              src={interview}
              title=""
            />
            <CardContent>
              <Typography className={classes.headingStyle} variant="h3" color="primary" gutterBottom>
                {data['chats']}
        </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                CONTACTS MADE
        </Typography>

            </CardContent>
          </CardActionArea>
        
        </Card>
      </Grid>




             
      <Grid item xs>
        <Card className={classes.card4}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              component="img"
              alt="Contemplative Reptile"

              src={card}
              title=""
            />
            <CardContent>
              <Typography className={classes.headingStyle} variant="h3" color="primary" gutterBottom>
                 ?
        </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                CANDIDATES ACCEPTED
        </Typography>

            </CardContent>
          </CardActionArea>
         
        </Card>
      </Grid>

     
       <Grid item xs>
       <Link href="/employer/dashboard/postajob" style={{textDecoration: 'none'}}>
        <Card className={classes.cardLast}>
        
          <CardActionArea>
            <CardMedia
              className={classes.mediaLast}
              component="img"
              alt=""

              src={plus}
              title=""
            />
            <CardContent>
              <Typography className={classes.headingStyle} variant="h3" color="primary" gutterBottom>
              
        </Typography>
              <Typography className={classes.title} style={{fontSize: 30,}} color="textSecondary" gutterBottom>
               
        </Typography>

            </CardContent>
          </CardActionArea>
         
         
        </Card>
        </Link>
       
      </Grid> 
     





    </Grid>

    </Box>








  );
}