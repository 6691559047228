import React, { useState, FunctionComponent } from 'react';
import { useSnackbar } from 'notistack';
import { apihost, Ways } from '../Helper';
import { CircularProgress, IconButton, Dialog, Card, Grid, ThemeProvider, createMuiTheme, Button, Link, Container, Box, AppBar, Toolbar, List, ListItem,  Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import { Work } from '@material-ui/icons';
import { createBrowserHistory } from 'history';
import { Visibility, Delete, Add } from '@material-ui/icons';
import axios from 'axios';
import { read_cookie } from 'sfcookies';
import { EmployerJobsTitleBar } from './EmployerJobsTitleBar';
import { StartACampaign } from './MyAccount/StartACampaign';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AssignJobDialog } from './AssignAJobDialog';
import { useEffect } from 'reactn';


export const EmployerJobsTable: FunctionComponent = x => {
    const [data, setData] = React.useState([]);
    const [isOpen, setIsOpne] = React.useState(false);
    const [cDoc, setCDoc] = React.useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const stripePromise = loadStripe('pk_test_51GthFbAA4CzqgPZ6UvHdA1dqA6I7RFKkXR00Vpf4LmeYfnQ8T3rl5yDHZ02RPZMOnWMhajKvFLwSM1erjOBxQwXY00ePrWOUxT')
    const getData = async () => {
        var d = await axios.get(apihost + '/employer/jobs', { headers: { userid: read_cookie('data')['id'] } });
        setData(d.data);
        setIsLoading(false);
        console.log(d.data);
    }
    useEffect(() => {

        getData();

    }, []);

    if (isLoading) {
        return <div style={{ textAlign: 'center' }}>
            <CircularProgress style={{ marginLeft: '45%', marginRight: '45%', marginBottom: '200px', marginTop: '200px' }} />
        </div>

    }

    return <div>
        <Grid item lg={12} xs={12} data-aos="fade-up" data-aos-duration="1500">
            <MaterialTable
                title='All Jobs'
                style={{ borderRadius: 15, boxShadow: 'none' }}

                columns={[
                    { title: 'id', render: (data) => <p>1</p> },
                    { title: 'Title', field: 'jobTitle' },
                    { title: 'Company', field: 'companyName' },
                    { title: 'Location', field: 'locationText' },
                    { title: 'Summery', field: 'jobShortDescription' },
                    { title: "Applicants", render: (data) => <div><Link href={Ways.employerSingleJob + data['_id'] + '/applicants'}>{data['applicants']}</Link></div> },
                    {
                        title: 'Actions', cellStyle: { width: '150px' }, render: (doc) =>
                        <div>
                        <List style={{display: 'flex', flexDirection: 'row',}}>
                            <ListItem>
                            <Elements stripe={stripePromise}>

                              <StartACampaign />
                              </Elements>

                            </ListItem>

                            <ListItem>
                                              
                          <Button variant="outlined" style={{ color: '#0bc26f', }} size='small' onClick={(x) => {
                              createBrowserHistory().push(Ways.employerSingleJob + doc['_id']);
                              window.location.reload();
                          }}>View</Button>
                                
                                </ListItem>

                                <ListItem>
                                <Button variant="outlined" size="small" color="secondary" style={{ marginLeft: 5, }} onClick={x => {
                              setCDoc(doc);
                              setIsOpne(true);
                              return;
                          }}>
                              Delete
                                 </Button>
                                
                                </ListItem>

                                <ListItem>
                                <AssignJobDialog />
                                
                                </ListItem>

                        </List>
                             

                           

                          
                        
                             
                      </div>
                    },
                ]}
                options={{
                    pageSize: 4, pageSizeOptions: []
                }} data={data} />
            <Dialog open={isOpen}>
                <Card style={{ padding: "30px" }}>
                    <p>Are you sure you want to delete?</p>
                    <Grid>
                        <ThemeProvider theme={createMuiTheme({
                            overrides: {
                                MuiButton: {
                                    text: {
                                        color: 'white'
                                    }
                                },
                            }
                        })}>
                            <Button style={{ background: 'red', marginRight: '10px' }} onClick={async x => {
                                await axios.delete(apihost + '/job', { headers: { jobid: cDoc['_id'] } }).then(x => {
                                    if (x.status == 200) {
                                        console.log(x.data);
                                        var newData = data.filter(x => {
                                            console.log(x['_id'] + '   ' + cDoc['_id']);
                                            return x['_id'] != cDoc['_id']
                                        });
                                        setData(newData);
                                    }
                                });
                                setIsOpne(false);
                                enqueueSnackbar('Job Listing Deleted', { color: 'red' });
                            }}>Delete</Button>
                        </ThemeProvider>
                        <Button onClick={x => setIsOpne(false)}>Cancel</Button>
                    </Grid>
                </Card>
            </Dialog>
        </Grid>
    </div>
}


export const EmployerAllJobsPage: FunctionComponent = x => {
    let a: any[] = [];
    const [data, setData] = React.useState(a);
    const [isOpen, setIsOpne] = React.useState(false);
    const [cDoc, setCDoc] = React.useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const stripePromise = loadStripe('pk_test_51GthFbAA4CzqgPZ6UvHdA1dqA6I7RFKkXR00Vpf4LmeYfnQ8T3rl5yDHZ02RPZMOnWMhajKvFLwSM1erjOBxQwXY00ePrWOUxT')

    let id: number = 1
    const getID = () => {
        return id++;
    }
    const getData = async () => {
        // setIsLoading(true);
        console.log('data :' + JSON.stringify(read_cookie('data')))
        // var d = await axios.get(apihost + '/employer/jobs', { headers: { userid: read_cookie('data')['id'] } });
        // setData(d.data);
        // setIsLoading(false);
    }
    const myTextIcon = React.useRef(null);

    React.useEffect(() => {
        getData();
    }, [])
    if (false) {
        return <div style={{ textAlign: 'center' }}>
            <CircularProgress style={{ marginLeft: '45%', marginRight: '45%', marginBottom: '200px', marginTop: '200px' }} />
        </div>

    } else {
        return <Box>
            <EmployerJobsTitleBar />

            <Container>

                <div style={{ marginTop: -120, }}>


                    <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" aria-label="menu">
                                <Work style={{ fontSize: 35, }} />
                            </IconButton>
                            <Typography variant="h4" >
                                All Jobs
                    </Typography>

                            <Button variant="outlined" style={{ color: '#ffffff', borderColor: '#efefef', marginLeft: 880, }} onClick={x => {
                                createBrowserHistory().push(Ways.postaJob);
                                window.location.reload();
                            }}>Add New job</Button>
                        </Toolbar>
                    </AppBar>
                </div>

                <EmployerJobsTable />


            </Container>

        </Box>

    }
}