import { AppBar, Container, CssBaseline, Divider, Drawer, Grid, Hidden, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Menu, Settings } from '@material-ui/icons';
import { createBrowserHistory } from 'history';
import React from 'react';
import 'typeface-roboto';
import { AdminLayout } from '../components/AdminLayout/AdminLayout';
import { AllCandidates } from '../components/common/AllCandidatesOfEmployer';
import { EmployersList } from '../components/EmployersList';
import { JobsTable } from '../components/JobsTable';
import { MyAccountLayout } from '../components/MyAccount/MyAccountLayout';
import { StickyHeader } from '../components/MyAccount/StickyHeader';
import { TitleBar } from '../components/TitleBar';
import { Ways } from '../Helper';
import lion from "./../assets/lion.jpg";


const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginBottom: 50,
    },

    buttonIcons: {
      color: '#ffffff',
    },

    logoStyle: {
      width: 100,
      height: 100,
      padding: 10,
      marginLeft: '25%',

    },

    firstContainer: {
      backgroundColor: '#fafafa',

    },

    // bodyContainer: {


    //     overflow: 'true',

    // },

    imgGrid: {


    },


    toolbarStyle: {
      backgroundColor: '#0B0B3A',
    },



    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: '#000000',


      },


    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: '#0B0B3A',



      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },

    toolbar: {
      theme: theme.mixins.toolbar
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#030314',
      color: '#ffffff',


    },

    linkStyle: {

      textDecoration: 'none',
    },


    sidebarIcons: {

      color: '#ffffff',
    },

    // stickyStyle: {

    //   maxWidth: '110%',
    //   backgroundColor: '#0B0B3A',
    //   position: 'sticky',
    //   zIndex: 5,
    // },

    boxesGridStyle: {

      // backgroundColor: '#ffffff',
      marginTop: 70,
      alignItems: 'center',
    },


    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
      marginTop: '7%',
    },

    adminRoot: {
      flexGrow: 1,


    },

    adminSecondRow: {
      marginTop: 50,
    },

  }),
);

interface ResponsiveDrawerProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container?: Element;
}

export const AdminDashboard = (props: ResponsiveDrawerProps) => {
  const { container } = props;
  const classes = useStyles({});
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  console.log(window.location.pathname);
  console.log(Ways.employerJobs);
  const listItem = [
    { title: 'Dashboard', path: Ways.adminDashboard, icon: <Link href={Ways.adminDashboard} className={classes.linkStyle} ><Settings className={classes.sidebarIcons} /></Link> },
    { title: 'Employers', path: Ways.adminEmployers, icon: <Link href={Ways.adminEmployers} className={classes.linkStyle} ><Settings className={classes.sidebarIcons} /></Link> },
    { title: 'Jobs', path: Ways.adminJobListings, icon: <Link href={Ways.adminEmployers} className={classes.linkStyle} ><Settings className={classes.sidebarIcons} /></Link> },
    { title: 'Candidates', path: Ways.adminCandidates, icon: <Link href={Ways.adminEmployers} className={classes.linkStyle} ><Settings className={classes.sidebarIcons} /></Link> },

  ]

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>

      <div className={classes.toolbar} />
      <Link href="/dashboard" className={classes.linkStyle}>
        <img className={classes.logoStyle} src={lion} alt="logo image" />
      </Link>
      <Divider />
      <List>
        {listItem.map((item) => (
          <ListItem button key={item.title} onClick={c => {
            createBrowserHistory().push(item['path']);
            window.location.reload();
          }}>
            <ListItemIcon className={classes.buttonIcons}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  // var topstick = window.location.pathname;
  // var sticky = <TitleBar />


  // if (topstick == '/dashboard/myaccount') {
  //   //sticky = <MyAccountLayout />
  // }


  var page = window.location.pathname;
  var content = <div >Invalid URL</div>

  if (page == Ways.adminDashboard) {
    content = <AdminLayout/>
  } else if (page == Ways.adminEmployers) {
    content = <EmployersList />
  } else if (page == Ways.adminJobListings) {
    content = <JobsTable />
  } else if (page.includes(Ways.employerSinglePage)) {
    content = <div>
      <StickyHeader />
      <MyAccountLayout />
    </div>
  
  }else if (page.includes(Ways.adminCandidates)){
    content = <AllCandidates/>
  }else if(page.includes(Ways.adminCandidateSinglePage)){
  }
  // }else if(something ==  'dashboard/singlejob'){
  //   content = <SingleJobLayout />
  // }else if(something == './dashboard/SingleJokkb'){
  //   content = <SingleJobLayout />
  // }

  return (
    <div className={classes.root}>


      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>

        <TitleBar />



        {/* <Header /> */}
        <Toolbar className={classes.toolbarStyle}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />





          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {window.location.pathname == '/employer/dashboard/account' && <StickyHeader />}

        <Container maxWidth='lg'>

          <Grid container >

            <Grid item lg={12} className={classes.boxesGridStyle}>
              {content}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}