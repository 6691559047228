import { Box, CircularProgress, Container, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Axios from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { apihost } from '../../Helper';
import { CandidateExperience } from './CandidateExperience';
import { CandidateLanguages } from "./CandidateLanguages";
import { CandidatePortfolio } from "./CandidatePortfolio";
// import { Profile } from "./../Chatroom/Profile";
import { CandidateProfile } from "./CandidateProfile";
import { CandidateSkills } from "./CandidateSkills";
import { Summary } from "./Summary";



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,


        },

    }),
);

export const SingleCandidatePage: FunctionComponent<{ email?: string }> = (props) => {

    const [userData , setUserData] = useState({});

    useEffect(() => {
        var id = window.location.pathname.split('/')[4];

        Axios.get(apihost + '/candidate/getbyid', { headers: { userid: id } }).then(x => {
            if(x.data['auth'] != null){
                var auth = x.data['auth'];
                var cv = x.data['cv']['cv'];
                var data = {
                    name : auth['name'],
                    email : auth['email'],
                    cv:cv
                };
                console.log(data);
                
                setUserData(data);
            }
        });
    } , [])
    const classes = useStyles({});

    if(userData['name'] == null) {
        return <div style={{textAlign :'center'}}>
        <CircularProgress style={{ marginLeft: '45%', marginRight: '45%', marginBottom: '200px', marginTop: '200px' }} />
    </div>
    }

    console.log(userData)

    return (
        <Box>
            <Container className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item lg={4} xs={12}>

                        <CandidateProfile userdata={userData}/>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <Summary userData={userData} />
                    </Grid>

                    <Grid item lg={4} xs={12}>

                    </Grid>
                    
                    <Grid item lg={4} xs={12}>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <CandidateLanguages userData={userData} />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <CandidateSkills userData={userData}/>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <CandidateExperience userData={userData}/>
                    </Grid>
                    <Grid item lg={8} xs={12}>
                        <CandidatePortfolio userData={userData}/>
                    </Grid>
                </Grid>

            </Container>

        </Box>
    );
}