import React, { FunctionComponent } from 'react';
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import 'typeface-roboto';
import { Card } from '@material-ui/core';
import { EventEmitter } from 'events';
import { bbc, AppEvent, camelize } from '../../Helper';
import { GlobalState } from '../../AppState';
import { observer } from 'mobx-react';
import Badge from '@material-ui/core/Badge';
import men from './../../assets/men.png';




const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
)(Badge);





const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 300,

      backgroundColor: theme.palette.background.paper,
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 0,
      paddingRight: 0,
    },

    heading: {
      backgroundColor: '#fbfbfb',
      color: '#000000',

    },

    listItemStyle: {

      borderBottom: '1px solid #efefef',
    },

    userName: {

      color: '#494646',
    },

    chatText: {

      color: '#6c79c7',

    },


    inline: {
      display: 'inline',
    },
  }),
);

export const PersonList = observer((props : { chats: any[] , onChatSelected : (x:any) =>void})=>{
  const classes = useStyles({});

  return (

    <List className={classes.root}>
      {/* <Typography variant="h6">All Conversations (12) </Typography> */}


      <ListItem alignItems="flex-start" >

        <Typography variant="h6" className={classes.chatText}>Chats ({props.chats.length})</Typography>
        {/* {"8 minutes agos"} */}

      </ListItem>
      {props.chats.map((x, i) => {
        return <div key={i}>
          <ListItem className={classes.listItemStyle} button onClick={()=>{
            bbc.emit(AppEvent.newChatSelected , x);
          }} alignItems="flex-start">
            <ListItemAvatar>
              
            <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar alt="Remy Sharp" src={men} />
      </StyledBadge>

            </ListItemAvatar>
            <ListItemText
            className={classes.userName}
              primary={camelize(String(x['candidateName']))}
              secondary={
                <React.Fragment>
               
                  <Typography variant="caption">12 minutes ago</Typography>
                  {/* {"8 minutes agos"} */}
                </React.Fragment>
              }
            />
          </ListItem>        
        </div>
      })}





    </List>
  );

}) 