import React, { FunctionComponent, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { EmployerLoginPage } from './pages/EmployerLoginPage';
import { Ways, setApiHost } from './Helper';
import { read_cookie } from 'sfcookies';
import { createBrowserHistory } from 'history';
import { Button } from '@material-ui/core';
import { NewEmployerDashboard } from './NewEmployerDashboard';
import { AdminDashboard } from './pages/AdminDashboard';
import { apihost } from './Helper';

import { EmployerSignUpPage } from './pages/EmployerSignUpPage';
import { PaymentTest } from './PaymentTest';
import { EmployerForgotPage } from './pages/EmployerForgotPage';
import { EmployerResetPage } from './pages/EmployerReset';
import { ApplicantsForJob } from './pages/ApplicantsForJob';

export const DevSettings: FunctionComponent = props => {
  const [host, setHost] = useState(apihost);
  return <div>
    <input value={host} onChange={x => {
      setHost(x.target.value)
    }} />
    <Button onClick={x => {
      setApiHost(host);
    }}>
      Save
    </Button>

  </div>
}

export const LoginChecker: FunctionComponent = x => {

  let cook =  read_cookie('data');
  console.log(cook);
  
  var path = window.location.pathname;
  console.log(path);
  
  var tryingLogin = path != '/' && path != Ways.employerLogin && path!=Ways.employerSignUp && path!= Ways.employerForgot && !path.includes(Ways.employerReset);
  if (cook.length ==0 ) {
    if(tryingLogin){
    createBrowserHistory().push('/');
    window.location.reload();

    }
  }

  return <div></div>;
}

export const LoginChoicePage: FunctionComponent = () => {

  return <div>
    <Button variant="contained">Login as Admin</Button>
    <Button variant="contained" onClick={e => {
      createBrowserHistory().push(Ways.employerLogin);
      window.location.reload();
    }}>Login as Employer</Button>
  </div>
}
const App: React.FC = () => {
  return (
    <Router>
      <Route path="/" component={LoginChecker} />
      <Route path="/" exact component={EmployerLoginPage} />
      <Route exact path="/login" component={EmployerLoginPage} />
      <Route exact path="/payment" component={PaymentTest} />
      <Route path={Ways.employerReset} component={EmployerResetPage}/>
      <Route exact path={Ways.employerForgot} component={EmployerForgotPage}/>

      {/* Employer Routes  */}
     
      <Route exact path={Ways.employerSignUp} component={EmployerSignUpPage} />
      <Route exact path={Ways.postaJob} component={NewEmployerDashboard} />
      <Route path={Ways.employerJobs} exact component={NewEmployerDashboard} />
      <Route path={Ways.employerDashBoard} exact component={NewEmployerDashboard} />
      <Route path={Ways.employerAllChats} component={NewEmployerDashboard} />

      <Route path={Ways.employerSingleJob} component={NewEmployerDashboard} />
      <Route path={Ways.employerCandidates} component={NewEmployerDashboard} />
      <Route path={Ways.candidateProfilePageForEmployer} component={NewEmployerDashboard} />
      <Route path={Ways.employerAccountPage} component={NewEmployerDashboard} />
      <Route path={Ways.employerAccountEditPage} component={NewEmployerDashboard} />

      {/* Admin Routes */}
      <Route exact path={Ways.adminEmployers} component={AdminDashboard} />
      <Route exact path={Ways.adminJobListings} component={AdminDashboard} />
      <Route path={Ways.employerSinglePage} component={AdminDashboard} />
      <Route path={Ways.adminSingleJob} component={AdminDashboard} />
      <Route path={Ways.adminCandidates} component={AdminDashboard} />
      <Route path={Ways.adminCandidateSinglePage} component={AdminDashboard} />
      <Route exact path={Ways.adminDashboard} component={AdminDashboard} />
      <Route exact path='/weirdpathforsettings' component={DevSettings} />
      <div style={{ textAlign: 'center' }}>ver: 22-sep-20</div>
    </Router>
  );
}

export default App;
