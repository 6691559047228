import { autorun, observable } from 'mobx';




export class AppState {
      @observable 
      userName: String = 'name';

      @observable 
      currentChat ?:any ;

      @observable
      messages : any[] = [];
}


autorun(() => {
      console.log(GlobalState);
});

export const GlobalState = new AppState();
window['store'] = GlobalState;



