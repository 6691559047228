import React, { useState } from "react"
import { Button, Dialog, Grid, ListItem, Typography, List, IconButton } from "@material-ui/core"
import { CardElement, useStripe, Elements, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { API, apihost } from "../../Helper";
import payment from './../../assets/payment.png';
import Axios from "axios";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Cancel } from "@material-ui/icons";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      
        btnStyle: {
            color: '#fff',
            backgroundColor: '#001224',
            width: '100%',
            boxShadow: 'none',

            '&.MuiButton-contained': {

                '&:hover': {

                    backgroundColor: '#001224',
                },
            },
        },

        slider: {

            width: '100%',
            padding: '2%',
        },
    }),
);


const marks = [
    {
      value: 0,
      label: '0 $',
    },
    {
      value: 20,
      label: '20 $',
    },
    {
      value: 40,
      label: '40 $',
    },
    {
      value: 60,
      label: '60 $',
    },
    {
        value: 80,
        label: '80 $',
      },
      {
        value: 100,
        label: '100 $',
      },
  ];
  
  function valuetext(value: number) {
    return `${value}$`;
  }

  


export const StartACampaign = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [close, setClose] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleClose = () => {
        setOpen(false);
      };



    return <div>
        <Button variant="outlined" size="small" onClick={() => {
            setOpen(true);
        }}>Boost</Button>

        <Dialog open={open} onClose={() => setOpen(false)} >
        <Grid container style={{  maxWidth: 700, minWidth: 350, minHeight: 550, paddingTop: '5%', }}>
                <Grid item lg={11} style={{marginBottom: '5%', paddingLeft: '3%', marginTop: '-3%'}}>
                    <br />
                    <Typography variant="h4" align="left">Boost Job</Typography>
                </Grid>
                <Grid item lg={1} style={{marginTop: '-4%'}}>
                    <IconButton  onClick={handleClose}>
                    <Cancel />
                    </IconButton>
                </Grid>

                <Grid item lg={12} xs={12}>

               
                <List style={{margin: 30, borderRadius: 5, backgroundColor: '#fbfbfb',}}>
                    <ListItem>
                    <Typography variant="h5" align="left">Estimated Daily Results</Typography>
                    </ListItem>
                    <ListItem>
                    <Typography variant="h6" align="left">Candidates Reached</Typography>
                    <Typography variant="body2" align="left">1K - 1.5K</Typography>
                    </ListItem>
                    <ListItem>
                    <Typography variant="h6" align="left">Applications</Typography>
                    <Typography variant="body2" align="left">350 - 600</Typography>
                    </ListItem>
                </List>

                <List style={{margin: 30, borderRadius: 5, backgroundColor: '#fbfbfb',}}>
                  
                   
                    <ListItem>
                    <Typography variant="h6" align="left">Budget</Typography>
                    <div className={classes.slider}>
                    <Typography id="discrete-slider-always" gutterBottom>
                        
                    </Typography>
                    <Slider
                 
                        track={false}
                        aria-labelledby="track-false-range-slider"
                        getAriaValueText={valuetext}
                        defaultValue={[20, 40]}
                        marks={marks}
                        valueLabelDisplay="auto"
                        
                    />
                    </div>
                    </ListItem>
                </List>

              

                </Grid>
                <Grid item lg={12}>
                {/* <fieldset className="FormGroup"> */}
                
            
      {/* </fieldset> */}


                    <fieldset className="FormGroup">
                    <Typography variant="h6" align="left" style={{marginLeft: '1%'}}>Payment</Typography>
                    <form className="FormRow" onSubmit={async (event) => {
                        event.preventDefault();

                        if (!stripe || !elements) {
                            // Stripe.js has not loaded yet. Make sure to disable
                            // form submission until Stripe.js has loaded.
                            return;
                        }

                        // Get a reference to a mounted CardElement. Elements knows how
                        // to find your CardElement because there can only ever be one of
                        // each type of element.
                        const cardElement = elements.getElement(CardElement);

                        // Use your card Element with other Stripe.js APIs
                        const result  = await stripe.confirmCardPayment((await Axios.post(apihost + '/startIntent')).data['client_secret'], {
                            payment_method: {
                                card: elements.getElement(CardElement),
                                billing_details: {
                                    name: 'John Doe'
                                }
                            }
                        });
                        console.log(result);
                        

                    }}>
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        iconColor: '#f28f16',
                                        
                                        color: '#000',
                                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                                        fontSize: '16px',
                                        fontSmoothing: 'antialiased',
                                        ':-webkit-autofill': {color: '#fce883'},
                                        '::placeholder': {color: '#000'},
                                      
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}
                        />
                            <br />
                            <Grid container>
                            <Grid item lg={12} xs={12}>
                                <ListItem>
                                    <Button className={classes.btnStyle} fullWidth variant="contained" type='submit'>Boost Now</Button>
                                </ListItem>

                            </Grid>
                          
                        </Grid>
                    </form>
                    </fieldset>
                </Grid>

                <Grid item xs={10}>
                <Typography align="center">
                    <img style={{marginLeft: '17%'}} src={payment} alt="image" />
                    </Typography>
                </Grid>
            </Grid>
        </Dialog>
    </div>
}