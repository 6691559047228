import React, { FunctionComponent } from "react";
import { Elements, CardElement, PaymentRequestButtonElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useEffect, useState } from "reactn";
import { loadStripe } from "@stripe/stripe-js";
import { Grid, Button } from "@material-ui/core";




const CheckoutForm = () => {
	const stripe = useStripe();
	const [paymentRequest, setPaymentRequest] = useState(null);
	const elements = useElements();


	const handleSubmit = async (event) => {
		// Block native form submission.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			console.log("Error");
			
			return;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);

		// Use your card Element with other Stripe.js APIs
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});
		
		if (error) {
			console.log('[error]', error);
		} else {
			console.log('[PaymentMethod]', paymentMethod);
		}
	};


	useEffect(() => {
		if (stripe) {
			var ol = {
				country: 'US',
				currency: 'usd',
				total: {
					label: 'Demo total',
					amount: 1099,
				},

				requestPayerName: true,
				requestPayerEmail: true,
			};
			const pr = stripe.paymentRequest({

				country: 'US',
				currency: 'usd',
				total: {
					label: 'Demo total',
					amount: 1099,
				},

				requestPayerName: true,
				requestPayerEmail: true,
			});

			pr.on('paymentmethod', (x) => {
				console.log(x);
				alert("Payment Complete")

			})

			pr.canMakePayment().then(result => {
				if (result) {
					alert("Payment Feature available")
					setPaymentRequest(pr);
				} else {
					alert("Cant make payment")
				}
			});

		}
	}, [stripe]);

	if (paymentRequest) {
		return <PaymentRequestButtonElement options={{ paymentRequest }} />
	}

	return <Button color='primary' onClick={handleSubmit}>Pay Now</Button>;
}

export const PaymentTest: FunctionComponent = () => {

	const [loaded, setLoaded] = useState({});

	useEffect(() => {
	}, []);
	const stripePromise = loadStripe('pk_test_51GthFbAA4CzqgPZ6UvHdA1dqA6I7RFKkXR00Vpf4LmeYfnQ8T3rl5yDHZ02RPZMOnWMhajKvFLwSM1erjOBxQwXY00ePrWOUxT')


	return <div >
		<Elements stripe={stripePromise}>
			<Grid>
				<CardElement
					options={{
						style: {
							base: {
								fontSize: '16px',
								color: '#424770',
								'::placeholder': {
									color: '#aab7c4',
								},
							},
							invalid: {
								color: '#9e2146',
							},
						},
					}}
				/>
				<CheckoutForm />
			</Grid>
		</Elements>
	</div>
}