import { Container } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      boxShadow: 'none',
      backgroundColor: '#002448',
      paddingTop: 80,
      paddingBottom: 40,
      zIndex: 20,
     
      
      
      
    },
    mainContainer: {
      backgroundColor: '#211F2C',
    },


   
  }),
);

export const PostJobTitleBar = () => {
  const classes = useStyles();

  return (
  
  
  <Container maxWidth="xl" style={{backgroundColor:'#002448', height:240, marginTop: -50,}}>

   

     
   
  </Container>
  
  );
}