import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import StarRateIcon from '@material-ui/icons/StarRate';
import ShareIcon from '@material-ui/icons/Share';
import { Fab } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalPlayIcon from '@material-ui/icons/LocalPlay';
import { Container, Grid, Checkbox } from '@material-ui/core';
import { Link } from "react-router-dom"
import 'typeface-roboto';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: '100%',
      flexDirection: 'row',
     
      paddingTop: 10,

    },

    linkStyle: {

      textDecoration: 'none',
    },


 


    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },

      mainContainer: {
        backgroundColor: '#ffffff',
        paddingTop: 30,
        paddingBottom: 50,
      },

    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },


    actionIconsStyle: {

        backgroundColor: '#e2e0e0',
    },

    avatar: {
      backgroundColor: red[500],
    },
  }),
);

export const JobCards = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (


<Container maxWidth="lg" className={classes.mainContainer}>
    <Grid container spacing={2} >

      {/* First Card */}
      <Grid item lg={12} xs={12}>
                <Typography variant="h5">
                    New Jobs
                </Typography>
            </Grid>
        <Grid item lg={12} xs={12}>


       <Link to="/dashboard/singlejob" className={classes.linkStyle}>
    <Card className={classes.card}>
  
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            R
          </Avatar>
        }
        action={
            <Fab size="small" aria-label="like">
            <StarRateIcon />
                </Fab>
        }
        title="Job Title"
        subheader="Company Name "
      />
      {/* <CardMedia
        className={classes.media}
        image="/static/images/cards/paella.jpg"
        title="Paella dish"
      /> */}
      <CardContent>
     
        <Typography variant="body2" color="textSecondary" component="p">
        This is the short description of the Job, for which the candidates applied.It tells us the job details to understand the job better.
          This is the short description of the Job, for which the candidates applied.
        </Typography>
      </CardContent>
      <CardActions className={classes.actionIconsStyle} disableSpacing >
        {/* <IconButton aria-label="add to favorites"> */}
                    <IconButton disabled>
                <LocationOnIcon />
                </IconButton>
                <Typography variant="caption">Montreal</Typography>
              
              
                <IconButton disabled>
                <LocalPlayIcon />
                </IconButton>
                <Typography variant="caption">Part Time</Typography>


                <IconButton disabled>
                <CreditCardIcon />
                </IconButton>
                <Typography variant="caption">2000-8000</Typography>


                <IconButton disabled>
                <QueryBuilderIcon />
                </IconButton>
                <Typography variant="caption">2 Days Ago</Typography>
                    
       
      </CardActions>
      
            
           
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        
        </CardContent>
      </Collapse>
    </Card>
    </Link>

                {/* Second Card */}

</Grid>



<Grid item lg={12} xs={12}>


<Link to="/dashboard/singlejob" className={classes.linkStyle}>
<Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            R
          </Avatar>
        }
        action={
            <Fab size="small" aria-label="like">
            <StarRateIcon />
                </Fab>
        }
        title="Job Title"
        subheader="Company Name"
      />
      {/* <CardMedia
        className={classes.media}
        image="/static/images/cards/paella.jpg"
        title="Paella dish"
      /> */}
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
        This is the short description of the Job, for which the candidates applied.It tells us the job details to understand the job better.
          This is the short description of the Job, for which the candidates applied.
        </Typography>
      </CardContent>
      <CardActions className={classes.actionIconsStyle} disableSpacing >
        {/* <IconButton aria-label="add to favorites"> */}
                    <IconButton disabled>
                <LocationOnIcon />
                </IconButton>
                <Typography variant="caption">Montreal</Typography>
              
              
                <IconButton disabled>
                <LocalPlayIcon />
                </IconButton>
                <Typography variant="caption">Part Time</Typography>


                <IconButton disabled>
                <CreditCardIcon />
                </IconButton>
                <Typography variant="caption">2000-8000</Typography>


                <IconButton disabled>
                <QueryBuilderIcon />
                </IconButton>
                <Typography variant="caption">2 Days Ago</Typography>
                    
       
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        
        </CardContent>
      </Collapse>
    </Card>
    </Link>


    </Grid>






    <Grid item lg={12} xs={12}>


    <Link to="/dashboard/singlejob" className={classes.linkStyle}>
<Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            R
          </Avatar>
        }
        action={
            <Fab size="small" aria-label="like">
            <StarRateIcon />
                </Fab>
        }
        title="Job Title"
        subheader="Company Name"
      />
      {/* <CardMedia
        className={classes.media}
        image="/static/images/cards/paella.jpg"
        title="Paella dish"
      /> */}
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          This is the short description of the Job, for which the candidates applied.It tells us the job details to understand the job better.
          This is the short description of the Job, for which the candidates applied.
        </Typography>
      </CardContent>
      <CardActions className={classes.actionIconsStyle} disableSpacing >
        {/* <IconButton aria-label="add to favorites"> */}
                    <IconButton disabled>
                <LocationOnIcon />
                </IconButton>
                <Typography variant="caption">Montreal</Typography>
              
              
                <IconButton disabled>
                <LocalPlayIcon />
                </IconButton>
                <Typography variant="caption">Part Time</Typography>


                <IconButton disabled>
                <CreditCardIcon />
                </IconButton>
                <Typography variant="caption">2000-8000</Typography>


                <IconButton disabled>
                <QueryBuilderIcon />
                </IconButton>
                <Typography variant="caption">2 Days Ago</Typography>
                    
       
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        
        </CardContent>
      </Collapse>
    </Card>
    </Link>


    </Grid>












    </Grid>
</Container>






  );
}