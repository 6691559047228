import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { MoreHoriz } from '@material-ui/icons';
import { Container, Grid, Button, List, ListItem, Divider, Stepper, Step, StepLabel, StepContent, Box, Avatar, IconButton, StepIcon  } from '@material-ui/core';
import portfolioimage from "./../portfolioimage.jpg";
import { RadioButtonUncheckedSharp } from '@material-ui/icons';
import men from './../../assets/men.png';
import 'typeface-roboto';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // card: {
    //   maxWidth: '100%',
    //   flexDirection: 'row',
     
    //   paddingTop: 10,
    //   paddingBottom: 50,
    //   backgroundColor: '#ffffff',

    // },

    summaryContainer: {


        backgroundColor: '#ffffff',
        
    },

    stepLabelActive: {

     color: '#efefef',
   
    },


    dot1: {

          color: '#ef1d26',

    },

    dot2: {
      color: '#0bc26f',
      
    },

    dot3: {
      color: '#0066bf',

    },

  


    dot4: {
      color: '#fec107',

    },

    dot5: {
      color: '#fe6707',

    },

        portfolioImg: {

            width: 200,
            height: 220,
        },
 


    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },


    btnLanguages: {

            display: 'flex',
            flexDirection: 'row',

    },

    AvatarIcons: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: -40,
        
    },

    mainContainer: {
        backgroundColor: '#ffffff',
        paddingTop: 30,
        paddingLeft: 50,
    },


    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
        stepperIcon: {
            color: '#efefef',
        },

        smallavatar: {

            marginLeft: 10,
            marginTop: -10,
            maxWidth: 30,
            maxHeight: 30,
        },

    actionIconsStyle: {

        backgroundColor: '#e2e0e0',
    },

    depositText1: {
      marginRight: 5,
    },

    depositText2: {
      marginLeft: 5,
    },

    // mainCard: {

    //     paddingTop: 50,
    //     paddingBottom: 30,
        
    // },

    avatar: {
      backgroundColor: red[500],
    },

    contentStyle: 
    {
      marginTop: -200,
    },

      textActivities: {
        color: '#0d64e5',
      },
        textBox: {
          display: 'flex',
          flexDirection: 'row',
          marginTop: -40,
        },

        timeStyle: {

          marginLeft: -30,
         

        },

        description: 
        {
          marginTop: -40,
        },

  }),
);

export const RecentActivities = () => {
  const classes = useStyles({});
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (

<Box>
    <Container maxWidth="lg" className={classes.mainContainer} >
    <Grid container spacing={2} >

      {/* First Card */}

{/* 
      <Grid item lg={12} xs={12}>
          <Typography variant="h6" align="left" gutterBottom>
              Education And Experience
          </Typography>
      </Grid> */}
      
     <Grid item lg={6} xs={6}>
         <Typography variant="h5">Recent Activities</Typography>
         </Grid>
         <Grid item lg={4} xs={4}>
         
         </Grid>
         <Grid item lg={2} xs={2}>
             <IconButton>
         <MoreHoriz />
         </IconButton>
         </Grid>
        <Grid item lg={12} xs={12}>
            <Divider />
        </Grid>
      <Grid item lg={12} xs={12}>


      
     










      
      <Stepper orientation="vertical" >
    <Step >
     <Typography variant="caption" className={classes.timeStyle}>10:00</Typography>
    {/* <StepLabel className={classes.stepLabelActive}> */}
   
    <RadioButtonUncheckedSharp className={classes.dot1} />
    
     
    {/* </StepLabel> */}

    <StepContent>
    {/* <Typography variant="h6" gutterBottom>Work Experience</Typography> */}
     
     {/* <Typography variant="body1" gutterBottom>Present-2015</Typography>
     <Typography variant="subtitle2" gutterBottom>Product Manager</Typography> */}
     <div className={classes.description}>
     <Typography variant="caption" className={classes.contentStyle} gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
     <Typography variant="caption" className={classes.contentStyle} gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
     </div>
    </StepContent>
    </Step>

    <Step active>
    {/* <StepLabel>
        12:45
    </StepLabel> */}
     <Typography variant="caption" className={classes.timeStyle}>12:30</Typography>
    <RadioButtonUncheckedSharp className={classes.dot2} />

    <StepContent>
    {/* <Typography variant="body1" gutterBottom>2012-2015</Typography> */}
    <div className={classes.AvatarIcons}>
      <Typography variant="subtitle2" gutterBottom>AEOL Meeting With</Typography>

      

      <img src={men} alt="placeholder image" className={classes.smallavatar} />
      <img src={men} alt="placeholder image" className={classes.smallavatar} />
      <img src={men} alt="placeholder image" className={classes.smallavatar} />
      <img src={men} alt="placeholder image" className={classes.smallavatar} />
      {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.smallavatar} />
      <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" className={classes.smallavatar} />
      <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" className={classes.smallavatar} />
      <Avatar alt="Jimmy Fallen" src="/static/images/avatar/4.jpg" className={classes.smallavatar} /> */}
      </div>
     
      {/* <Typography variant="caption" gutterBottom>Telecom Private LTD</Typography>
      <Typography variant="body2" gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography> */}
    </StepContent>
    </Step>


    
    <Step active>
    <Typography variant="caption" className={classes.timeStyle}>02:40</Typography>
    <RadioButtonUncheckedSharp className={classes.dot3} />
    {/* <StepLabel>
        14:00
    </StepLabel> */}

    <StepContent>
    {/* <Typography variant="body1" gutterBottom>2009-2012</Typography> */}
    <div className={classes.textBox}>
      <Typography variant="subtitle2" className={classes.depositText1}  gutterBottom>Make Deposit  </Typography>
      
      <Typography variant="subtitle2" className={classes.textActivities}   gutterBottom>USD $700  </Typography>
      <Typography variant="subtitle2" className={classes.depositText2}  gutterBottom> to ESL.</Typography>
      </div>
      {/* <Typography variant="caption" gutterBottom>Telecom Private LTD</Typography>
      <Typography variant="body2" gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography> */}
    </StepContent>
    </Step>




    <Step active>
    {/* <StepLabel>
    16:15
    </StepLabel> */}
     <Typography variant="caption" className={classes.timeStyle}>05:10</Typography>
       <RadioButtonUncheckedSharp className={classes.dot4} />
    <StepContent>
    <div className={classes.description}>
      <Typography variant="caption"  gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
      </div>
    </StepContent>
    </Step>



    <Step active>
    <Typography variant="caption" className={classes.timeStyle}>11:00</Typography>
    <RadioButtonUncheckedSharp className={classes.dot5} />
    {/* <StepLabel>
    18:00
    </StepLabel> */}
        
    <StepContent>
    
    <div className={classes.textBox}>
      <Typography variant="subtitle2" className={classes.depositText1}  gutterBottom>Place order in  </Typography>
      
      <Typography variant="subtitle2" className={classes.textActivities}   gutterBottom>Signature Mobile</Typography>
      <Typography variant="subtitle2" className={classes.depositText2}  gutterBottom>Market Place.</Typography>
      </div>
  
    </StepContent>
    </Step>




    <Step active>
    <Typography variant="caption" className={classes.timeStyle}>03:00</Typography>
    <RadioButtonUncheckedSharp className={classes.dot1} />
    {/* <StepLabel>
        19:30
    </StepLabel> */}

    <StepContent>
      <div className={classes.description}>
      <Typography variant="caption"  gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
      </div>

    </StepContent>
    </Step>




    <Step active>
    <Typography variant="caption" className={classes.timeStyle}>04:00</Typography>
    <RadioButtonUncheckedSharp className={classes.dot1} />
    {/* <StepLabel>
        19:30
    </StepLabel> */}

    <StepContent>
      <div className={classes.description}>
      <Typography variant="caption"  gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
      </div>

    </StepContent>
    </Step>


    
    <Step active>
   

    <StepContent>
   
    </StepContent>
    </Step>



    
</Stepper>
        
      </Grid>


                                      
            

    </Grid>
</Container>
</Box>





  );
}