import { Badge, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import React, { FunctionComponent } from 'react';
import 'typeface-roboto';
import profile from './../../assets/profile.jpg';
import profile2 from './../../assets/profile2.jpg';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    //   marginTop: 300,
      borderRadius: 15,
      paddingBottom: 20,
      paddingTop: 20,
    },

    ImgStyle: {

width: 100,
height: 70,
borderRadius: 8,
marginBottom: 5,

    },

    listIcon: {

      color: '#6c79c7',
    },

  

    customBadge: {
      backgroundColor: "#41d18c",
      color: "white",
      marginTop:15,
      marginRight: 15,
      width: 10,
      height: 10,
      border: '2px solid rgb(255, 255, 255)',
    
    },
    
    customBadge2: {
      backgroundColor: "#ef1d26",
      color: "white",
      marginTop:15,
      marginRight: 15,
      width: 10,
      height: 10,
      border: '2px solid rgb(255, 255, 255)',
    
    },


    customBadge3: {
      backgroundColor: "#ff8300",
      color: "white",
      marginTop:15,
      marginRight: 15,
      width: 10,
      height: 10,
      border: '2px solid rgb(255, 255, 255)',
    
    },

    imgText: {
position: 'absolute',
fontSize: 12,
marginTop: 50,
marginLeft: 12,
alignItems: 'center',
color: '#ffffff',

    },

    headings: {
color: '#797980',
    },



  }),
);

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

export const Members:FunctionComponent<{members?:any}> = props => {
  const classes = useStyles({});

  return (
    // <div className={classes.root}>
   

    <Container className={classes.root}>
       <Typography variant="h6" className={classes.headings} noWrap>
            Members <br /><br />
          </Typography>
   <Grid container direction="row" justify="center" alignItems="center">
    {/* <Grid item xs={12} md={8} lg={7}> */}
   
   
   
    <Grid item  lg={4} xs={4} sm={3}>
      
        
        <Badge  variant="dot" color="secondary" classes={{ badge: classes.customBadge }} >
        <img src={profile} alt="simple image"  className={classes.ImgStyle} />
        <Typography variant="caption" className={classes.imgText}>Alexa Becker</Typography>
       </Badge>
      
        
    
      
    </Grid>

    {/* <Grid item xs={12} md={4} lg={5}> */}
    <Grid item  lg={4} xs={4} sm={3}>
      
      <Badge variant="dot" color="secondary" classes={{ badge: classes.customBadge2}} >
      <img src={profile2} alt="simple image" className={classes.ImgStyle} />
      <Typography variant="caption" className={classes.imgText}>Jeney Loren</Typography>
      </Badge>
      
     

    </Grid>


    <Grid item  lg={4} xs={4} sm={3}>
      
      <Badge variant="dot" color="secondary" classes={{ badge: classes.customBadge3}} >
      <img src={profile} alt="simple image" className={classes.ImgStyle} />
      <Typography variant="caption" className={classes.imgText}>Russel Petr</Typography>
      </Badge>
    
     

    </Grid>

    
    {/* <Grid item xs={12} md={8} lg={7}> */}
    <Grid item  lg={4} xs={4} sm={3}>
    <Badge variant="dot" color="secondary" classes={{ badge: classes.customBadge3}} >
      <img src={profile2} alt="simple image"  className={classes.ImgStyle} />
      <Typography variant="caption" className={classes.imgText}>Mandy Elky</Typography>
      </Badge>
        
      
    </Grid>

    {/* <Grid item xs={12} md={4} lg={5}> */}
    <Grid item  lg={4} xs={4}>
 

    </Grid>


    <Grid item  lg={4} xs={4} >
      

    </Grid>

    <Grid item xs>
    <List component="nav" aria-label="main mailbox folders" className={classes.listIcon}>
        <ListItem button>
          <ListItemIcon>
            <ArrowRightAltIcon className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText primary="Go To Gallery" />
          </ListItem>
          </List>

    </Grid>

    </Grid>








    </Container>



    // </div>
  );
}