
import { delete_cookie, read_cookie, bake_cookie } from 'sfcookies';
import { createBrowserHistory } from 'history';
import * as firebase from 'firebase';
import { createProvider } from 'reactn';
import axios from 'axios';
import { EventEmitter } from 'events';



const webApi = 'https://jobland-server.uk.r.appspot.com';
const local = 'http://localhost:8080';
const server = 'http://34.71.74.232:8080';
const heroku = 'https://jobland-api.herokuapp.com';
let url = 'https://joblandsecurebackend.tk';

export let apihost = url;

export const setApiHost = (x: string) => {
    apihost = '';
}

export const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// This could be very important. Gets nested value of nested objects
export const getvalue = (obj, key) => {
    return key.split(".").reduce(function (o, x) {
        return (typeof o == "undefined" || o === null) ? o : o[x];
    }, obj);
}

export enum Lafz {
    Employer_Single_Page
}

const scrolls: { text: Lafz, fr: string, en: string }[] = [
    {
        text: Lafz.Employer_Single_Page,
        fr: 'Un petit flashback de Keiley!',
        en: 'A Little FlashBack Of Keiley!'

    }
]



export const camelize = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}

export class LangHelper {

    static change = (lancode: string) => {
        bake_cookie('localdata', { lang: lancode })
        window.location.reload();
    }
    static lang = () => {
        var cook = read_cookie('localdata')
        if (cook['lang'] != null) return cook['lang']
        return 'en';
    }
    static cl = LangHelper.lang();

    static HomePageHelloText = LangHelper.cl == 'en' ? 'Hello There' : 'Ola';

    static get = (text: Lafz) => {
        var res = scrolls.find(x => x.text == text)
        return res[LangHelper.cl];
    }

}

export class AppEvent {
    static newChatSelected: string = 'newChatSelected'
    static newMessageRecieved: string = 'newMessageRecieved'
}

export const bbc = new EventEmitter();


export const logOut = () => {
    alert(read_cookie('data'));

    if (read_cookie('data') != null) {
        delete_cookie('data');
        createBrowserHistory().push('/');
        window.location.reload();
    }
}

export class API {

    static get = async (endpoint: string, headers?: any) => {
        headers['client-id'] = '12345';
        console.log(headers);
        return await axios.get(apihost + '/'+ endpoint, {headers : headers});
    }
    
    static post = async (endpoint: string, headers?: {}, body?: {}) => {
        headers['client-id'] = '12345';
        return await axios.post(apihost +'/' + endpoint, body, { headers });
    }

    static getCookieData() {
        return read_cookie('data');
    }

    static saveCookie(data: any) {
        bake_cookie('data', data);
    }

    static TryEmployerLogin = async (email: string, password: string): Promise<any> => {
        let x = await axios.post(apihost + '/employer/login', {
            email: email,
            password: password
        });
        console.log(x);

        if (x.status != 200) {
            return x.data;
        }
        x.data['email'] = email;
        API.saveCookie(x.data);


        let messaging = firebase.messaging();
        let notificationPersmission = await Notification.requestPermission();

        while (notificationPersmission != 'granted') {
            let notificationPersmission = await Notification.requestPermission();

            if (notificationPersmission != 'granted') {
                alert('permission denied - DONT LET THIS HAPPEN');
            } else {

            }
        }

        await axios.post(apihost + '/employer/updateToken', { token: await messaging.getToken(), email: email }).then(x => {
            console.log(String(x.data))
        });
        createBrowserHistory().push(Ways.employerDashBoard);
        window.location.reload();
    }

}

export const logourl = 'https://firebasestorage.googleapis.com/v0/b/jobland-app.appspot.com/o/logo.png?alt=media&token=515e8c5e-cf40-4669-9db8-e561c9da13cb';


export class Ways {

    static adminDashboard = '/admin/dashboard'
    static adminEmployers: string = '/admin/dashboard/employers';
    static adminJobListings: string = '/admin/dashboard/jobs';
    static employerSinglePage = '/admin/dashboard/employer/'
    static adminSingleJob = '/admin/job/'
    static adminCandidates = '/admin/candidates'
    static adminCandidateSinglePage = '/admin/candidate'



    // Employer ways

    static employerForgot = '/employer/forgot'
    static employerReset = '/passwordreset/'

    static employerDashBoard = '/employer/dashboard';
    static employerLogin = '/login';
    static employerSignUp = '/employer/signup';
    static postaJob = '/employer/dashboard/postajob';
    static employerJobs = '/employer/dashboard/jobs';
    static employerAllChats = '/employer/dashboard/chats'
    static employerSingleJob = '/employer/job/'
    static applicantsforJob = '/employer/jobs/applicants/'
    static employerCandidates = '/employer/mycandidates'
    static candidateProfilePageForEmployer = '/employer/dashboard/candidate/'
    static employerAccountPage = '/employer/dashboard/account';
    static employerAccountEditPage = '/employer/dashboard/accountEdit';

    // 

    static go = (x: string) => {
        createBrowserHistory().push(x);
        window.location.reload();
    }
}


