import * as React from 'react';
import { TextField, makeStyles, Switch, TextareaAutosize, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { Label } from '@material-ui/icons';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';

const useStyles = makeStyles({
      underline: {
            "&&&:before": {
                  borderBottom: "none"
            },
            "&&:after": {
                  borderBottom: "none"
            }
      }
});

export const EditableText: React.FunctionComponent<{ readOnly?: boolean , label?:string, wide?: boolean, text: string, onChange: (x: String) => void, isEditing: boolean, rows?: number }> = props => {
      const [text, setText] = React.useState(props.text);
      let classes = useStyles({});
      var isOn = props.isEditing;
      return <div>
            {isOn && <TextField label={props.label} variant='outlined' multiline rows={props.rows} rowsMax={10} style={props.wide ? { width: '95%' ,marginTop : 10, marginBottom:10 } : {marginTop : 10, marginBottom:10}} aria-label="maximum width" defaultValue={text} onChange={e => {
                  setText(e.target.value)
                  if (props.onChange != null) {
                        props.onChange(e.target.value);
                  }
            }} />}
            {!isOn && <TextField label={props.label} variant='outlined' multiline disabled rows={props.rows} rowsMax={10} style={props.wide ? { width: '95%' ,marginTop : 15, marginBottom:10} : {marginTop : 10, marginBottom:10}} aria-label="maximum width" defaultValue={text} />}
      </div>
}

export const EditableSelect: React.FunctionComponent<{ value?: any, isEditing: boolean, onChange?: (x) => void, options?: string[] , label?:string ,wide?:boolean }> = props => {

      const [currentItem, setCurrentItem] = React.useState(props.value);

      var isOn = props.isEditing;

      return <div>

            {isOn && <ValidatorForm onSubmit={() => { }}>
                        <SelectValidator
                              name={'other'}
                              
                              label={props.label}
                              style={props.wide ? { width: '95%' ,marginTop : 10 , marginBottom : 10} : {marginTop : 10, marginBottom:10}}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={currentItem}
                              variant='outlined'
                              onChange={x => {
                                    setCurrentItem(x.target['value'])
                                    props.onChange(x.target['value']);
                              }}>
                              {props.options.map(x => <MenuItem value={x}>{x}</MenuItem>)}

                        </SelectValidator>
                  </ValidatorForm>
            }

            {!isOn &&
                  <ValidatorForm onSubmit={() => { }}>
                        <SelectValidator
                              name={'other'}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={currentItem}
                              fullWidth={props.wide}
                              label={props.label}
                              style={props.wide ? { width: '100%' ,marginTop : 15} : {marginTop : 15}}
                              variant='outlined'
                              disabled
                              onChange={x => {
                                    setCurrentItem(x.target['value'])
                                    props.onChange(x.target['value']);
                              }}>
                              {props.options.map(x => <MenuItem value={x}>{x}</MenuItem>)}

                        </SelectValidator>
                  </ValidatorForm>
            }


      </div>
}


export const EditableTextBox: FunctionComponent<{ text: string, onChange: (x: string) => void, label: string, isEditing: boolean }> = props => {
      let isOn = props.isEditing;
      return <div style={{ marginTop: '10px' }}>
            {isOn && <TextField label={props.label} value={props.text} variant='outlined' onChange={x => props.onChange(x.target['value'])}>
            </TextField>}
            {
                  !isOn && <p><strong>{props.label + ' : '}</strong>{props.text}</p>
            }
      </div>
}
