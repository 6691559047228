import React, { FunctionComponent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { List, ListItem } from "@material-ui/core";
import { bbc, AppEvent } from '../../Helper';
import { useEffect } from 'reactn';
import placeholder from './../../assets/placeholder.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
    //   maxWidth: 345,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
     
    },
    media: {
      height: 50,
      width: 50,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },


    headings: {

        opacity: '70%',
    },
    avatar: {
      // backgroundColor: red[500],
      width: 150,
      height: 150,
      border: '1px solid #6574C4',
    },
  }),
);

export const  Profile:FunctionComponent<{candidate? : string}> = props=> {
  const classes = useStyles({});
  const [expanded, setExpanded] = React.useState(false);
  const [chat , setChat] = React.useState();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(()=>{
    bbc.on(AppEvent.newChatSelected , (x)=>{
      setChat(x);
    })
  } , []);

  if(chat ==null){
    return <div></div>
  }

  return (
    <Card className={classes.card} elevation={0} >
        <List>
        <ListItem alignItems="flex-start" >
      
      <Typography className={classes.headings} variant="h6">Profile</Typography>
      {/* {"8 minutes agos"} */}
 


</ListItem>
</List>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" src={placeholder} className={classes.avatar}>
            {String(chat['candidateName']).substring(0,1)}
          </Avatar>
        }
      />

      <Typography className={classes.headings} variant="h6" >{chat['candidateName']}</Typography>
    
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {chat['candidateEmail']}
        </Typography>
      </CardContent>
     
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        
        </CardContent>
      </Collapse>
    </Card>
  );
}