import React, { FunctionComponent, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import Axios from 'axios';
import { apihost, Ways } from '../Helper';
import { FullScreenLoading } from './JobsTable';
import { Button, IconButton, Dialog, Card, Grid, ThemeProvider, createMuiTheme } from '@material-ui/core';
import {Delete , Info} from '@material-ui/icons';
import { useSnackbar } from 'notistack';

export const EmployersList: FunctionComponent = props => {

      const [data , setData] = useState([]);
      const [isLoading , setIsLoading] = useState(true);
      const [isOpen , setIsOpen] = useState(false);
      const [cDoc , setCDoc] = useState();
      const {enqueueSnackbar} = useSnackbar();

      useEffect(()=>{
            Axios.get(apihost + '/employers').then(x=>{
                  console.log(x.data['employers']);
                  setData(x.data['employers']);
                  setIsLoading(false);
            })
      } , []);

      if(isLoading){
            return <FullScreenLoading/>
      }
      return <div>
            <MaterialTable
            title='Employers'
            columns={[
                  {title:'Namee' , field:'company'},
                  {title:'Industry' , field:'industry'},
                  {title:'Agent' , field:'agent'},
                  {title:'Phone' , field:'phone'},
                  {title:'Web Site' , field:'website'},
                  {title:'Email' , field:'email'},
                  {title:'Actions' , render:(d)=>{
                        return <div>
                            <IconButton onClick={x=>{
                              setCDoc(d);
                              setIsOpen(true);
                        }}>
                              <Delete/>
                        </IconButton>
                        <IconButton onClick={x=>{
                            Ways.go(Ways.employerSinglePage + d['_id']);
                        }}>
                            <Info/>
                        </IconButton>
                        </div>
                        
                  }}
                  
            ]}

            data={data}
            />
             <Dialog open={isOpen}>
                <Card style={{ padding: "30px" }}>
                    <p>Are you sure you want to delete?</p>
                    <Grid>
                        <ThemeProvider theme={createMuiTheme({
                            overrides: {
                                MuiButton: {
                                    text: {
                                        color: 'white'
                                    }
                                },
                            }
                        })}>
                            <Button style={{ background: 'red', marginRight: '10px' }} onClick={async x => {
                                await Axios.delete(apihost + '/employer', {  headers: { employerid: cDoc['_id'] }  , data : {raw : 'raw data'}}).then(x => {
                                    console.log(x.data);
                                    
                                    if (x.status == 200) {
                                        var newData = data.filter(x => {
                                            console.log(x['_id'] + '   ' + cDoc['_id']);
                                            return x['_id'] != cDoc['_id']
                                        });
                                        setData(newData);
                                    }
                                });
                                setIsOpen(false);
                                enqueueSnackbar('Employer Deleted', { color: 'red' });
                            }}>Delete</Button>
                        </ThemeProvider>
                        <Button onClick={x => setIsOpen(false)}>Cancel</Button>
                    </Grid>
                </Card>
            </Dialog>
      </div>
}