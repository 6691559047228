import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { start } from './Start';

// const theme = createMuiTheme({
//   palette: { primary: purple }
// });





// Set loading to true.


ReactDOM.render(
  <SnackbarProvider  maxSnack={4} anchorOrigin={{horizontal:'right' , vertical:'top'}} >
    {/* <ThemeProvider theme={theme}> */} 
        <App/>
    {/* </ThemeProvider> */}
  </SnackbarProvider>
  , document.getElementById('root'));
  serviceWorker.register();
  start()


