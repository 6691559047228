
import { AppBar, Badge, Button, CircularProgress, Container, Grid, IconButton, Link, Toolbar, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Group } from '@material-ui/icons';
import Axios from 'axios';
import { createBrowserHistory } from 'history';
import MaterialTable from 'material-table';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { API, apihost, Ways, camelize } from '../../Helper';
import men from './../../assets/men.png';
import { SingleJobCandidatesTitleBar } from './SingleJobCandidatesTitleBar';




const useStyles = makeStyles((theme: Theme) =>
    createStyles({


        customBadge: {
            backgroundColor: "#41d18c",
            color: "white",
            marginTop: 15,
            marginRight: 15,
            width: 10,
            height: 10,
            border: '2px solid rgb(255, 255, 255)',

        },

        imageGrid: {

            marginTop: 15,


        },




    }),
);




export const AllCandidatesForEmployer: FunctionComponent = () => {
    const [candidates, setCandidates] = useState();
    useEffect(() => {
        var data = API.getCookieData();

        Axios.get(apihost + '/employer/allcandidates', { headers: { employer: data['id'] } }).then(x => {
            if (x.status == 200) {
                var can = x.data.map(x => {
                    var obj = {}
                    obj = x['candidate'];
                    obj['job'] = x['job'];
                    return obj;
                });
                setCandidates(can);
            }
        });
    }, []);
    if (candidates == null) {
        return <div style={{ textAlign: 'center' }}>
            <CircularProgress />
        </div>
    }

    return <Grid container style={{ boxShadow: 'none', }} className="jobsAnimation">
        <Grid item lg={12} xs={12}>


            <MaterialTable
                style={{ boxShadow: 'none', borderBottom: 'none' }}

                title='New Candidates'
                columns={[
                    { title: 'Id', render: () => <div>111</div> },

                    {
                        field: "name",
                        title: "Name",
                        render: rowData => (

                            <Grid container direction="row">
                                <Grid item lg={2}>
                                    <img
                                        style={{ height: 28, marginTop: 26, paddingRight: 10, borderRadius: "50%" }}
                                        src={men} />
                                </Grid>
                                <Grid item lg={2}>
                                    <p style={{ marginLeft: 15, marginTop: 26, }}>{camelize(String(rowData.name))}</p>
                                </Grid>
                            </Grid>





                        ),


                    },
                    // { field: 'name', title: 'Name' },
                    { field: 'email', title: 'Email' },
                    {
                        title: 'Job', render: (data) => <div>
                            <Link href={Ways.adminSingleJob + data['job']['_id']}>{data['job']['jobTitle']}</Link>
                        </div>
                    }, { title: 'Location', render: () => <div>Montreal</div> },


                    {
                        title: 'Status', render: () =>
                            <Grid container direction="row">
                                <Grid item lg={1}>
                                    <Badge variant="dot" color="primary" style={{ marginRight: -5, }}>
                                    </Badge>
                                </Grid>
                                <Grid item lg={2}>
                                    Active
								  </Grid>
                            </Grid>

                    },



                    {
                        title: 'Actions', render: (rd) => {
                            return <Grid direction='row' spacing={1} container >
                                <Grid item lg={6}>
                                    <Button variant='outlined' style={{ color: '#0bc26f' }} size='small' onClick={() => {
                                        createBrowserHistory().push(Ways.candidateProfilePageForEmployer + rd['_id']);
                                        window.location.reload();
                                    }}>View</Button>

                                </Grid>
                                <Grid item lg={6}>
                                    <Button variant='outlined' size='small' color='secondary'
                                        onClick={() => {
                                            Axios.get(apihost + '/getchat', { headers: { employer: API.getCookieData()['email'], candidate: rd['email'] } }).then(x => {
                                                createBrowserHistory().push('/employer/dashboard/chats/' + x.data);
                                                window.location.reload();
                                            })
                                        }}>
                                        Chat
						</Button>
                                </Grid>
                            </Grid>
                        }
                    }]}
                data={candidates}
            />
        </Grid>
    </Grid>

}

export const AllCandidatesForJob: FunctionComponent = () => {
    const [candidates, setCandidates] = useState();
    const [job, setJob] = useState({})
    useEffect(() => {
        var data = window.location.pathname.split('/');
        console.log(data);
        Axios.get(apihost + '/employer/applicants', { headers: { job: data[3] } }).then(x => {
            if (x.status == 200) {
                console.log(x.data);
                setCandidates(x.data);
                setJob(x.data[0]['job'])
            }
        });

    }, []);
    if (candidates == null) {
        return <div style={{ textAlign: 'center' }}>
            <CircularProgress />
        </div>
    }
    return <div style={{ marginTop: -60, }}>
        <SingleJobCandidatesTitleBar />
        <Container style={{ marginTop: -50, }} className="jobsAnimation">


            <Grid item lg={12} xs={12}>
                <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none', marginTop: -120, }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu">
                            <Group style={{ fontSize: 35, }} />
                        </IconButton>
                        <Typography variant="h4" >
                            Candidates For Lead Designer
					</Typography>

                        {/* <Button variant="outlined" className={classes.btnStyle}>Add Job</Button> */}
                    </Toolbar>
                </AppBar>
            </Grid>
            <Grid item lg={12} xs={6}>


                <MaterialTable
                    style={{ borderRadius: 15, boxShadow: 'none', }}
                    //  title={'Candidates for ' + job['jobTitle']}
                    columns={[
                        { title: 'Id', render: () => <div>111</div> },
                        { field: 'candidate.name', title: 'Name' },
                        { field: 'candidate.email', title: 'Email' },
                        {
                            title: 'Job', render: (data) => <div>
                                <Link href={Ways.employerSingleJob + data['job']['_id']}>{data['job']['jobTitle']}</Link>
                            </div>
                        },
                        { title: 'Location', render: () => <div>Montreal</div> },
                        { title: 'Status', render: () => <div>Refused</div> },
                        {
                            title: 'Action', render: (rd) => {
                                return <Grid direction='row' spacing={1} container >
                                    <Grid item lg={6}>
                                        <Button variant='outlined' color='primary' onClick={() => {
                                            createBrowserHistory().push(Ways.candidateProfilePageForEmployer);
                                            window.location.reload();
                                        }}>View</Button>

                                    </Grid>
                                    <Grid item lg={6}>
                                        <Button variant='outlined'
                                            onClick={() => {
                                                Axios.get(apihost + '/getchat', { headers: { employer: API.getCookieData()['email'], candidate: rd['email'] } }).then(x => {
                                                    createBrowserHistory().push('/employer/dashboard/chats/' + x.data);
                                                    window.location.reload();
                                                })
                                            }}>
                                            Chat
					</Button>
                                    </Grid>
                                </Grid>
                            }
                        }]}
                    data={candidates}

                />
            </Grid>
        </Container>
    </div>
}

export const AllCandidates: FunctionComponent = () => {
    const [candidates, setCandidates] = useState();
    const [job, setJob] = useState({})

    let id: number = 1
    const getID = () => {
        return id++;
    }
    useEffect(() => {
        var data = window.location.pathname.split('/');
        console.log(data);
        Axios.get(apihost + '/admin/candidates').then(x => {
            if (x.status == 200) {
                console.log(x.data);
                setCandidates(x.data);
            }
        });

    }, []);
    if (candidates == null) {
        return <div style={{ textAlign: 'center' }}>
            <CircularProgress />
        </div>
    }
    return <MaterialTable
        style={{ boxShadow: 'none' }}
        title='All Candidates'
        columns={[
            { title: 'Id', render: () => <div>{getID()}</div> },
            { field: 'name', title: 'Name' },
            { field: 'email', title: 'Email' },
            { title: 'Location', render: () => <div>Montreal</div> },
            {
                title: 'Action', render: (rd) => {
                    return <Grid direction='row' spacing={1} container >
                        <Grid item lg={6}>
                            <Button variant='outlined' color='primary' onClick={() => {
                                createBrowserHistory().push(Ways.candidateProfilePageForEmployer);
                                window.location.reload();
                            }}>View</Button>

                        </Grid>
                    </Grid>
                }
            }]}
        data={candidates}

    />

}