import { AppBar, Badge, Box, Button, CircularProgress, Container, Grid, IconButton, Link, Toolbar, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Group } from '@material-ui/icons';
import Axios from 'axios';
import { createBrowserHistory } from 'history';
import MaterialTable from 'material-table';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { API, apihost, Ways } from '../../Helper';
import men from './../../assets/men.png';
import { CandidateTableTitleBar } from './CandidatesTableTitleBar';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    

customBadge: {
	backgroundColor: "#41d18c",
	color: "white",
	marginTop:15,
	marginRight: 15,
	width: 10,
	height: 10,
	border: '2px solid rgb(255, 255, 255)',
  
  },

  imageGrid: {

	marginTop: 15,


  },


  topBar: {

	backgroundColor: '#002448',
	boxShadow: 'none',
	// paddingTop: 90,
  
	alignItems: 'stretch',
   
},

  menuButton: {
	marginRight: theme.spacing(2),
   
  },
  title: {
	flexGrow: 1,
  //   display: 'none',
	[theme.breakpoints.up('sm')]: {
	  display: 'block',
	},
  },




}),
);




export const CandidatesTable: FunctionComponent = () => {
	const [candidates, setCandidates] = useState();
	useEffect(() => {
		var data = API.getCookieData();

		Axios.get(apihost + '/employer/allcandidates', { headers: { employer: data['id'] } }).then(x => {
			if (x.status == 200) {
				var can = x.data.map(x => {
					var obj = {}
					obj = x['candidate'];
					obj['job'] = x['job'];
					return obj;
				});
				setCandidates(can);
			}
		});
	}, []);
	if (candidates == null) {
		return <div style={{ textAlign :'center' }}>
			<CircularProgress />
		</div>
	}

	return<Box>
        <CandidateTableTitleBar />
                <Container>

					<Grid item lg={12} xs={12} style={{marginTop: -120,}}>
					<AppBar position="static" style={{background: 'transparent', boxShadow: 'none'}}>
						<Toolbar>
						<IconButton edge="start"  color="inherit" aria-label="menu">
							<Group style={{fontSize: 35,}}  />
						</IconButton>
						<Typography variant="h4" >
							All Candidates
						</Typography>
						{/* <Button variant="outlined" className={classes.btnStyle}>Save</Button> */}
						</Toolbar>
					</AppBar> 
					</Grid>
			<Grid item lg={12} xs={6} data-aos="fade-up" data-aos-duration="1500">

		
	 <MaterialTable
      style={{borderRadius: 15, boxShadow: 'none',}}
		title=''
		columns={[
			{ title: 'Id', render: () => <div>111</div> },
			
			{  field: "name",
			title: "Name",
				render: rowData => (
				
							<Grid container direction="row">
						<Grid item lg={2}>
							<img
					  style={{ height: 28, marginTop: 26, paddingRight: 10, borderRadius: "50%" }}
					  src={men} />
					  </Grid>
						<Grid item lg={2}>
							<p style={{marginLeft: 15, marginTop: 26,}}>{rowData.name}</p>
							</Grid>
							</Grid>
						

					
					
				  
				  ),
			
		
			},
			// { field: 'name', title: 'Name' },
			{ field: 'email', title: 'Email' },
			{
				title: 'Job', render: (data) => <div>
					<Link href={Ways.employerSingleJob + data['job']['_id']}>{data['job']['jobTitle']}</Link>
				</div>
			}, { title: 'Location', render: () => <div>Montreal</div> },
			

				  {title: 'Status', render: () => 
			  				<Grid container direction="row">
								  <Grid item lg={1}>
								  <Badge variant="dot" color="primary" style={{marginRight: -5,}}>

								  </Badge>
								  </Grid>

								  <Grid item lg={2}>
			  						Active
								  </Grid>
							  </Grid>
				  
				},



			{
				title: 'Actions' , render: (rd) => {
					return <Grid direction='row' spacing={1} container > 
						<Grid item lg={6}>
						<Button variant='outlined' style={{color: '#0bc26f'}} size='small' onClick={() => {
						createBrowserHistory().push(Ways.candidateProfilePageForEmployer);
						window.location.reload();
					}}>View</Button>
						
						</Grid>
						<Grid item lg={6}>
						<Button variant='outlined' size='small' color='secondary'
							onClick={() => {
								Axios.get(apihost + '/getchat', { headers: { employer: API.getCookieData()['email'], candidate: rd['email'] } }).then(x => {
									createBrowserHistory().push('/employer/dashboard/chats/' + x.data);
									window.location.reload();
								})
							}}>
							Chat
						</Button>
						</Grid>
					</Grid>
				}
			}]}
		data={candidates}
	/>
		</Grid>
        </Container>
		</Box>
	
}