import { Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { API, Ways } from '../Helper';
import lion from './../assets/lion.jpg';
import { ValidText } from '../components/common/ValidatedText';
import { useSnackbar } from 'notistack';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

	},

	LoginContainer: {

		backgroundColor: '#ffffff',
		paddingBottom: 30,

	},

	link: {

		textDecoration: 'none',
	},

	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#4B4A6C',
	},
}));


export const EmployerLoginPage: FunctionComponent = props => {
	const classes = useStyles({});
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [emailError, setEmailError] = useState('')
	const [error, setError] = useState(false);

	const { enqueueSnackbar } = useSnackbar();


	return (
		<Container component="main" maxWidth="xs" className={classes.LoginContainer}>
			<CssBaseline />
			<div className={classes.paper}>
				{/* <Avatar className={classes.avatar}> */}
				<img src={lion} alt="top logo" />
				{/* <LockOutlinedIcon />
		    </Avatar> */}
				<Typography component="h1" variant="h5">
					Sign in
		    </Typography>

				<ValidatorForm
					onSubmit={async x => {

						if (error) {
							enqueueSnackbar("Please Resolve Errors");
							return;
						}
						var data = await API.TryEmployerLogin(email, password);
						if(data!=null){
							enqueueSnackbar(data);
						}else{
							enqueueSnackbar("LoggedIn")
						}
						// API.TryEmployerLogin(email, password)
					}}
					onError={errors => console.log(errors)}
				>
					<TextValidator
						label="Email"
						onChange={x => setEmail(x.target['value'])}
						name="email"
						value={email}
						fullWidth
						variant="outlined"
						margin="normal"
						required
						autoComplete="email"
						autoFocus
						validators={['required', 'isEmail']}
						errorMessages={['this field is required', 'email is not valid']}
					/>

					<TextValidator
						label="Password"
						value={password}
						fullWidth
						variant="outlined"
						margin="normal"
						required
						onChange={e => setPassword(e.target['value'])}
						name="password"
						// label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						autoFocus
						validators={['required']}
						errorMessages={['this field is required']}
					/>
					<Grid container>
						<Grid item xs>
							<FormControlLabel
								control={<Checkbox value="remember" color="primary" />}
								label="Remember me"


							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={async x => {
						
						}}
						className={classes.submit}
					>
						Sign In
			</Button>
				</ValidatorForm>
				{/* <TextField
					error={emailError.length > 0}
					helperText={emailError}

					id="email"
					onChange={e => {
						if (!e.target.value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) {
							setEmailError("Enter Valid Email");
						} else {
							setEmailError('');
						}
						return setEmail(e.target.value);
					}}
					// label="Email Address"
					fullWidth
					variant="outlined"
					margin="normal"
					required
					name="email"
					autoComplete="email"
					autoFocus
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					onChange={e => setPassword(e.target.value)}
					name="password"
					// label="Password"
					type="password"
					id="password"
					autoComplete="current-password"
				/> */}




{/* 
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					onClick={x => {
						if (error) {
							enqueueSnackbar("Please Resolve Errors");
							return;
						}
						API.TryEmployerLogin(email, password);
					}}
					className={classes.submit}
				>
					Sign In
			</Button> */}
				<Grid container>
					<Grid item xs>
						<Link href={Ways.employerForgot} variant="body2">
							Forgot password?
			    </Link>
					</Grid>
					<Grid item>
						<Link href="/employer/signup" variant="body2">
							{"Create Account"}
						</Link>
					</Grid>
				</Grid>
			</div>
			<Box mt={8}>

			</Box>
		</Container>)

}