import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, MenuItem, FormControl, Select, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);




export const AssignJobDialog = () => {
    const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen}>
        Assign
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Assign Job To User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            For assigning a job to user, please select the user from the following list!
          </DialogContentText>
        <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Select User</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
         
          label="Select User"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>User 1</MenuItem>
          <MenuItem value={20}>User 2</MenuItem>
          <MenuItem value={30}>User 3</MenuItem>
          <MenuItem value={10}>User 4</MenuItem>
          <MenuItem value={20}>User 5</MenuItem>
          <MenuItem value={30}>User 6</MenuItem>
        </Select>
      </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}