import React, { FunctionComponent, useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, Grid, TextField, Card, Button, FormControl, InputLabel, MenuItem, AppBar, Toolbar, IconButton, Select, Box, Container, Typography, CssBaseline, Link } from '@material-ui/core';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { WorkOutline } from '@material-ui/icons';
import { PostJobTitleBar } from './../../pages/PostJobTitleBar';
import UploadImage, { NewUploadImage } from './UploadImage';
import { API, apihost } from '../../Helper';




const UseNewStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '-9%',


    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      marginTop: '-28%',
    },


  },



  LoginContainer: {

    backgroundColor: '#ffffff',



    borderRadius: 15,

    marginTop: '-10',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      marginTop: '-28%',
    },



  },


  topBar: {

    backgroundColor: 'transparent',
    boxShadow: 'none',
    // paddingTop: 90,

    alignItems: 'stretch',

  },

  btnStyle: {

    color: '#ffffff',
    borderColor: '#efefef',

    '&:hover': {

      color: '#efefef',
      borderColor: '#efefef',



    },
  },

  iconStyle: {
    fontSize: 35,

  },


  menuButton: {
    marginRight: theme.spacing(2),

  },
  title: {
    flexGrow: 1,
    //   display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  link: {

    textDecoration: 'none',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#4B4A6C',
  },
}));

export const SignupForm = () => {
  const classes = UseNewStyles({});

  const [bio, setBio] = useState('')
  const [birthday, setBirthday] = useState('')
  const [address, setAddress] = useState('')
  const [state, setResState] = useState('')
  const [city, setCity] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [logo, setLogo] = useState('')

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    Axios.get(apihost + '/employer/getprofile', { headers: { employer: API.getCookieData()['id'] } }).then(res => {
      var d = res.data;
      setLogo(d['logo'])
      setBio(d['bio']);
      setBirthday(d['birthday'])
      setAddress(d['address'])
      setResState(d['state'])
      setCity(d['city'])
      setZipcode(d['zipcode'])
      // console.log(d)
    })
  }, [])

  return (
    <Box>
      <PostJobTitleBar />
      <Container className={classes.root}>
        <Grid item lg={12} xs={12} >

          <AppBar position="static" className={classes.topBar}>
            <Toolbar>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <WorkOutline className={classes.iconStyle} />
              </IconButton>
              <Typography variant="h4" className={classes.title}>
                Settings
      </Typography>

            </Toolbar>
          </AppBar>
        </Grid>
        <Grid container spacing={1} data-aos="fade-up" data-aos-duration="1500">
          <Grid item lg={12} xs={12} style={{ backgroundColor: '#ffffff', padding: 25, marginTop: 15, borderRadius: 15, }}>
            <NewUploadImage image={logo} onUploaded={url => {
              setLogo(url)
            }} />
            <TextField
              variant="outlined"
              id="outlined-multiline-static"
              multiline
              margin="normal"
              required
              fullWidth
              rows="6"

              name="Long Description"
              rowsMax="16"
              // label="Password"
              type="text"
              value={bio}
              onChange={e => setBio(e.target.value)}
              label="Bio"
            // autoComplete="current-password"
            />


            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              // label="Email Address"
              name="Title"
              value={birthday}
              onChange={r => setBirthday(r.target.value)}

              autoComplete="email"
              label="Birthday"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={address}
              onChange={r => setAddress(r.target.value)}
              name="address"
              // label="Password"
              type="text"
              id="text"
              label="Company Address"
            // autoComplete="current-password"
            />




            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="Location"
              value={city}
              onChange={r => setCity(r.target.value)}
              // label="Password"
              type="text"
              id="text"
              label="City"
            // autoComplete="current-password"
            />


            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="Location"
              value={state}
              onChange={r => setResState(r.target.value)}
              // label="Password"
              type="text"
              id="text"
              label="State"
            // autoComplete="current-password"
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="Location"
              value={zipcode}
              onChange={r => setZipcode(r.target.value)}
              // label="Password"
              type="text"
              id="text"
              label="Zip Code"
            // autoComplete="current-password"
            />

            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              //bio birthday address state city zipcode
              onClick={async r => {
                var body = {
                  bio: bio,
                  birthday: birthday,
                  address: address,
                  state: state,
                  city: city,
                  zipcode: zipcode,
                  logo: logo
                }
                var id = API.getCookieData()['id'];
                await Axios.post(apihost + '/employer/updateprofile', body, { headers: { employer: id } }).then(x => {
                  if (x.status == 200) {
                    enqueueSnackbar("Changes Saved")
                  } else {
                    enqueueSnackbar("There was an error", { color: 'red' });
                  }
                });
              }}
            >
              Save
          </Button>


          </Grid>


        </Grid>
      </Container>
    </Box>
  )
};


