import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Grid, Box, Container, Typography } from '@material-ui/core';
import { LayoutCards } from './LayoutCards';
import { JobCards } from './JobCards';
import { AdminTable } from './AdminTable';
import { RecentActivities } from './RecentActivities';
import { AllCandidates } from '../common/AllCandidatesOfEmployer';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      
      
    },

    secondRow: {
        marginTop: 50,
    },
 
  }),
);

export const AdminLayout = () => {
  const classes = useStyles();


  return (

    <Box>
    <Container className={classes.root}>
      <Grid container spacing={2}>

      <Grid item lg={12} xs={12}>
         <Typography variant="h5">Welcome! Admin</Typography>
        </Grid>


        <Grid item lg={12} xs={12} >
          <LayoutCards />
        </Grid>


        <Grid item lg={12} xs={12} >
          <AllCandidates />
        </Grid>

        <Grid item lg={6} xs={12} className={classes.secondRow}>
         <JobCards />
        </Grid>

        <Grid item lg={6} xs={12} className={classes.secondRow}>
         <RecentActivities />
        </Grid>


     




        
      </Grid>
      
    </Container>

    </Box>
  );
}