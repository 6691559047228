import { Button, Container, CssBaseline, makeStyles, TextField, DialogContent, Typography, Dialog, Link } from '@material-ui/core';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useState } from 'react';
import { apihost, Ways } from '../Helper';
import lion from './../assets/lion.jpg';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const useStyles = makeStyles(theme => ({
  signupPage: {

  },

  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 30,
  },


  formFields: {
    marginTop: 10,
    marginBottom: 10,
  },
  topSpace: {
    marginTop: 30,
  },

  signupContainer: {
    backgroundColor: '#ffffff',
    paddingBottom: 30,
    marginBottom: 50,


  },

}));

export const EmployerSignUpPage: FunctionComponent = x => {
  let classes = useStyles({});
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [agentName, setAgentName] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [email, setEmail] = useState('');
  const [isOpen ,setIsOpen] = useState(false);

  const [password, setPassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  return <Container component="main" maxWidth="xs" className={classes.signupContainer}>
    <CssBaseline />
    <div className={classes.paper}>

      <img src={lion} height='100' />

      <ValidatorForm onSubmit={e => {
        var profile = {
          company: companyName,
          industry: industry,
          agent: agentName,
          phone: phone,
          website: website,
          email: email,
          password: password
        }
        Axios.post(apihost + '/employers/signup', profile).then(x => {
          if (x.status == 200) {
            setIsOpen(true);
            // Ways.go('/login');
          } else {
            console.log(x);
            enqueueSnackbar(x.data);
          }
        })
      }
      }>


        <TextValidator className={classes.formFields}
          variant='outlined'
          margin="normal"
          label='Company Name'
          fullWidth
          name='Company Name'
          required

          value={companyName}
          validators={['required', 'matchRegexp:^[a-zA-Z]+$']}
          errorMessages={['this field is required', 'Company name must not have Numbers in it']}
          onChange={e => setCompanyName(e.target['value'])}>

        </TextValidator>


        <TextValidator
          className={classes.formFields}
          name='Industry'
          value={industry}
          required

          validators={['required', 'matchRegexp:^[a-zA-Z]+$']}
          errorMessages={['this field is required', 'Industry name must not have Numbers in it']}

          variant='outlined' label='Industry'
          fullWidth
          margin="normal"
          onChange={e => setIndustry(e.target['value'])} />







        <TextValidator
          className={classes.formFields}
          variant='outlined'
          margin="normal"
          label='Resource Agent Name'
          fullWidth
          name='agent'
          required

          value={agentName}
          validators={['required', 'matchRegexp:^[a-zA-Z]+$']}
          errorMessages={['this field is required', 'Agent Name cannot contain numbers or Special Characters']}
          onChange={e => setAgentName(e.target['value'])} />

        <TextValidator
          className={classes.formFields}
          variant='outlined'
          label='Phone'
          margin="normal"
          fullWidth
          name='phone'
          value={phone}
          required
          validators={['required', 'isNumber']}
          errorMessages={['this field is required', 'Phone number can only have digits']}
          onChange={e => setPhone(e.target['value'])} />

        <TextValidator
          className={classes.formFields}
          variant='outlined'
          margin="normal"
          label='Company Website'
          name='website'

          value={website}

          fullWidth onChange={e => setWebsite(e.target['value'])} />


        <TextValidator
          className={classes.formFields}
          variant='outlined'
          label='Email'
          margin="normal"
          required
          fullWidth
          name='email'
          value={email}
          validators={['required', 'isEmail']}
          errorMessages={['this field is required', 'Invalid Email']}
          onChange={e => setEmail(e.target['value'])} />


        <TextValidator
          className={classes.formFields}
          variant='outlined'
          label='Password'
          fullWidth
          margin="normal"
          required
          name='password'
          value={password}
          validators={['required', 'minStringLength:6']}
          errorMessages={['this field is required', 'Password must be at least 5 characters']}
          onChange={e => setPassword(e.target['value'])} />


        <Button
          type="submit"

          variant="contained"
          color="primary"
          onClick={e => {
            // var profile = {
            //   company: companyName,
            //   industry: industry,
            //   agent: agentName,
            //   phone: phone,
            //   website: website,
            //   email: email,
            //   password: password
            // }
            // Axios.post(apihost + '/employers/signup', profile).then(x => {
            //   if (x.status == 200) {
            //     Ways.go(Ways.employerLogin);
            //   } else {
            //     console.log(x);
            //     enqueueSnackbar('error occured. check console for details');
            //   }
            // })
          }}
          fullWidth
        >Register</Button>
      </ValidatorForm>



      <p>Already have account? Login <a href={Ways.employerLogin}>Here</a></p>

    </div>

    <Dialog open={isOpen} onClose={x=>setIsOpen(false)}>
      <DialogContent style={{padding:'4%'}}>
        <Typography>Your account has been created. <Link href={Ways.employerLogin}>Login</Link></Typography>
      </DialogContent>
    </Dialog>
  </Container>
}