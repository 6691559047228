import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import lion from "./../../assets/lion.jpg";
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Container, Divider, ListItem, List } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import 'typeface-roboto';
import { Ways, apihost, API } from '../../Helper';
import Axios from 'axios';
import { CloudUpload } from '@material-ui/icons';
import firebase from 'firebase';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: '100%',






  },



  //   menuButton: {
  //     marginRight: theme.spacing(2),
  //   },
  toolbar: {
    minHeight: 128,
    alignItems: 'flex-start',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    backgroundColor: '#002448',
    borderBottom: 0,


  },
  logoStyle: {
    width: 100,
    height: 100,
    padding: 10,

  },

  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
    marginLeft: 10,
    marginTop: 25,


  },

  followers: {
    marginTop: 20,
    padding: 5,
    marginRight: 5,


  },

  headerStyle: {

    minHeight: 128,
    borderBottom: 0,
    paddingTop: 70,
    backgroundColor: '#002448',
    marginTop: -90,
    paddingRight: 40,
    paddingLeft: 60,
    boxShadow: 'none',


  },




  topText: {

    fontSize: '5',
    marginTop: 25,



  },

  followersList:
  {

    display: 'flex',
    flexDirection: 'row',
  },

  divider: {
    marginTop: 25,

    height: 32,
    width: 1,
    margin: 4,
    display: "inline-flex",
    borderRight: '0.1em solid white',
  },

  menuContainer: {


    alignItems: 'flex-start',
    backgroundColor: '#002448',
    paddingBottom: 60,
    color: '#ffffff',
    maxWidth: '100%',
  
    paddingTop: 20,

    [theme.breakpoints.up('xl')]: {
     paddingLeft: '10%',
     paddingRight: '10%',

    },

  },




}));

export const StickyHeader = () => {
  const classes = useStyles({});
  const [profile,setProfile] = useState({});
  const [logo , setLogo] = useState();
  let place ='http://www.sona.ie/media/catalog/product/cache/1/image/130x130/9df78eab33525d08d6e5fb8d27136e95/placeholder/default/thumbnail_4.jpg'

  useEffect(()=>{
    Axios.get(apihost + '/employer/getprofile', { headers: { employer: API.getCookieData()['id'] } }).then(x => {
      setProfile(x.data);
      setLogo(x.data['logo']);
    });
  },[])

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.headerStyle}>
        <Toolbar className={classes.toolbar}>

          <Container maxWidth="lg">
          <Grid container>
            <Grid item lg={1}>
              <img className={classes.logoStyle} src={logo!=null ? logo : place} alt="logo image" />
             
              <form onSubmit={x=>{
                  
                }}>
               <input accept="image/*"
                   className="custom-file-input"

                   id="icon-button-file"
                   multiple
                   type="file" onChange={async e=>{
                     e.preventDefault();

                     let reader = new FileReader();
                     let file = e.target.files[0];
                     reader.onloadend = () => {
                         // this.setState({ file: file, imagePreviewUrl: reader.result.toString() });
                         //setImagePreview(render.result.toString());
                         var ref = firebase.storage().ref(API.getCookieData()['id'] + '/logo')
                         var uploadTask = ref.put(file);
                         uploadTask.then(async x => {
                             var downloadUrl = await ref.getDownloadURL();
                             // props.onUploaded(downloadUrl);
                             setLogo(downloadUrl);
                             console.log(downloadUrl);
                         })
             
                     }
                     reader.readAsDataURL(file)
                   }} />

               {/* <Button variant="contained" type="submit" color="primary" onClick={this._handleSubmit}>Upload Logo</Button> */}
           </form>
            </Grid>
            

            <Grid item lg={2}>
              <Typography className={classes.title} variant="subtitle2" noWrap>
                {profile['employer']!=null ? profile['employer']['company'] : ''}<br />
            {profile['address']}
          </Typography>
            </Grid>

            <Grid item lg={5} xs={1} sm={1} md={4}>

            </Grid>

            <Grid item lg={2}>
              <List className={classes.followersList}>
                <ListItem>
                  <Typography variant="caption" className={classes.topText} noWrap>
                    2K+<br />
                   Followers
                 </Typography>
                </ListItem>

                <ListItem>
                  <Divider orientation="vertical" className={classes.divider} />
                </ListItem>


                <ListItem>
                  <Typography variant="caption" className={classes.topText} noWrap>
                    847<br />
                  Following
                  </Typography>
                </ListItem>


              </List>

            </Grid>



            <Grid item lg={2}>

            </Grid>




          </Grid>



          </Container>

        </Toolbar>





      </AppBar>



      <Container className={classes.menuContainer}>
        <Grid container alignItems="center"  >


          <Grid item lg={1} xs={1}>

          </Grid>



          <Grid item lg={1} xs={2} >
            <Link href="#" color="inherit">
              Timeline
                 </Link>
          </Grid>



          <Grid item lg={1} xs={2} >
            <Link href="#" color="inherit">
              About
                 </Link>
          </Grid>




          <Grid item lg={1} xs={2} >
            <Link href="#" color="inherit">
              Photos
                 </Link>
          </Grid>


          <Grid item lg={1} xs={2} >
            <Link href="#" color="inherit">
              More
                 </Link>
          </Grid>

          <Grid item lg={2}>

          </Grid>

          <Grid item lg={3}></Grid>




          <Grid item lg={2} xs={2} justify="flex-end" direction="row" >
            <IconButton arial-label="search" color="inherit" onClick={r=>Ways.go(Ways.employerAccountEditPage)}>

              <SettingsIcon />
              <Typography variant="caption" >Settings</Typography>
            </IconButton>
          </Grid>




        </Grid>

      </Container>





    </div>
  );
}