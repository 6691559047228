import { AppBar, Badge, BottomNavigation, BottomNavigationAction, Button, Container, CssBaseline, Divider, Drawer, Grid, Hidden, Link, List } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { AccountBox, ExitToApp, Group, LibraryAdd, Mail, Settings, Work } from '@material-ui/icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import 'typeface-roboto';
import lion from "./assets/lion.jpg";
import { EmployerChats } from './components/Chatroom/NewChatRoom';
import { AllCandidatesForEmployer, AllCandidatesForJob } from './components/common/AllCandidatesOfEmployer';
import { SingleCandidatePage } from './components/common/Candidatepage';
import { CandidatesTable } from './components/common/CandidatesTable';
import { EmployerTitleBar } from './components/Employer/EmployerTitleBar';
import { JobCards } from './components/Employer/JobCards';
import { LayoutCards } from './components/Employer/LayoutCards';
import { RecentActivities } from './components/Employer/RecentActivities';
import { EmployerAllJobsPage } from './components/EmployerJobs';
import { EmployerSingleJob } from './components/EmployerSingleJob';
import { MyAccountLayout } from './components/MyAccount/MyAccountLayout';
import { StickyHeader } from './components/MyAccount/StickyHeader';
import { TitleBar } from './components/TitleBar';
import './dashboardStyles.css';
import { Ways } from './Helper';
import { PostJob } from './pages/PostAJobPage';
import { SignupForm } from './components/Employer/SignupForm';
import { SidebarMenu } from './components/SideBarMenu';

AOS.init();





const drawerWidth = 180;



const useStyles = makeStyles((theme: Theme) =>



  createStyles({
    root: {
      display: 'flex',
      elevation: 0,
      marginBottom: 50,


    },

    bottomNavigation: {
      selected: 'false',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#211f2c',
      paddingBottom: 30,
      paddingTop: 30,
      marginLeft: 15,
      minWidth: 130,


      //  '& .Mui-selected': {
      //        backgroundColor: '#fbfbfb',
      //  },

      '&:hover': {

        backgroundColor: '#1a1823',
        minheight: 120,

      },


      // '& .MuiBottomNavigationAction-wrapper.Mui-selected': {
      //   backgroundColor: '#000',

      // }




    },

    buttonIcons: {
      color: '#ffffff',
    },

    logoStyle: {
      width: 100,
      height: 100,
      padding: 10,
      marginLeft: '22%',

    },

    firstContainer: {
      backgroundColor: '#fafafa',

    },



    imgGrid: {


    },


    toolbarStyle: {
      backgroundColor: '#211f2c',
      boxShadow: 'none',

    },

    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: '#211f2c',

      },


    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: '#FAFAFA',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 2px 5px 0px rgba(0,0,0,0.0), 0px 1px 0px 0px rgba(0,0,0,0.1)',


      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },

    toolbar: {
      theme: theme.mixins.toolbar
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#211f2c',
      color: '#ffffff',
      paddingTop: 20,
      paddingBottom: 20,

      flexDirection: 'column',

    },

    navList: {
      display: 'flex',
      flexDirection: 'column',

    },

    actions: {

      color: '#fbfbfb',
      '&:hover': {
        textDecoration: 'none',
        color: '#ffffff'
      }
    },


    sidebarLinks: {
      textColor: '#efefef',


      '&:hover': {

        color: '#ffffff',
        listStyle: 'none',
      }

    },

    linkStyle: {

      textDecoration: 'none',
      color: '#efefef',


    },


    sidebarIcons: {

      color: '#9a9a9e',
      '&:hover': {
        color: '#ffffff',
      }

    },



    boxesGridStyle: {

      // backgroundColor: '#ffffff',
      marginTop: 0,
      alignItems: 'center',
    },


    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
      marginTop: '6%',
      overflow: 'hidden',
      //added new line overflow:hidden



      [theme.breakpoints.up('xs')]: {
        marginTop: '10%',
      },

      [theme.breakpoints.up('xl')]: {
        marginTop: '5%',
      },

      [theme.breakpoints.up('sm')]: {
        marginTop: '6%',
      },

      [theme.breakpoints.up('md')]: {
        marginTop: '4%',
      },
    },

    adminRoot: {
      flexGrow: 1,



    },

    sidebarBtn: {

      '&:selected': {
        backgroundColor: '#000000',

      }
    },



    adminSecondRow: {
      marginTop: 30,
      autoWidth: 'false',


    },

    tableContainer: {

      postition: 'relative',
      overflow: 'hidden',
      autoWidth: 'false',
      marginTop: 30,
    },



  }),
);

interface ResponsiveDrawerProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container?: Element;
}

export const NewEmployerDashboard = (props: ResponsiveDrawerProps) => {
  const { container } = props;
  const classes = useStyles({});
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  console.log(window.location.pathname);
  console.log(Ways.employerJobs);
  const listItem = [
    { title: 'Dashboard', path: Ways.employerDashBoard, icon: <Link href={Ways.employerDashBoard} className={classes.linkStyle} ><Settings className={classes.sidebarIcons} /></Link> },
    { title: 'Jobs', path: Ways.employerJobs, icon: <Badge badgeContent={99} color="secondary" ><Work className={classes.sidebarIcons} /></Badge> },
    // { title: 'Reports', path: '/dashboard/jobs', icon: <Assignment /> },
    { title: 'Candidates', path: Ways.employerCandidates, icon: <Badge badgeContent={65} color="primary" ><Group className={classes.sidebarIcons} /></Badge> },
    // { title: 'Company', path: '/dashboard/jobs', icon: <Dvr /> },
    { title: 'Message', path: Ways.employerAllChats, icon: <Badge badgeContent={12} color="secondary" > <Mail className={classes.sidebarIcons} /></Badge> },
    { title: 'Post a job', path: Ways.postaJob, icon: <LibraryAdd className={classes.sidebarIcons} /> },
    { title: 'Account', path: Ways.employerAccountPage, icon: <AccountBox className={classes.sidebarIcons} /> },

    { title: 'Logout', path: '/', icon: <Link href="/" className={classes.linkStyle}><ExitToApp className={classes.sidebarIcons} /></Link> },
  ]

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>

      <div className={classes.toolbar} />
      <Link href={Ways.employerDashBoard} className={classes.linkStyle}>
        <img className={classes.logoStyle} src={lion} alt="logo image" />
      </Link>
      <Divider />

      <List>
        {/* {listItem.map((item) => (
         
       <Button
       className={classes.sidebarBtn}
       
       href={item.path}>
          <BottomNavigation
          
          value={value}
        
          
           
          onChange={(event, newValue) => {
           setValue(newValue);
          }}
          showLabels
          className={classes.bottomNavigation}
          
          
        
        >

          
          <BottomNavigationAction className={classes.sidebarLinks} style={{color: '#efefef'}}  label={item.title} icon={item.icon} />
         
        </BottomNavigation></Button>
      
        ))} */}
        <SidebarMenu />
      </List>
    </div>
  );




  var page = window.location.pathname;
  var content = <div>Found Nothing</div>

  if (page == Ways.employerDashBoard) {
    content = <div> <EmployerTitleBar />
      <Container >







        <Grid container spacing={2} data-aos="fade-up" data-aos-duration="1500" className={classes.adminRoot}>

          {/* <Grid item lg={12} xs={12}>
            <Typography variant="h5">Welcome! {API.getCookieData()['agent']}</Typography>
          </Grid> */}
          <Grid item lg={12} xs={12} style={{ marginTop: -80, }}>
            <LayoutCards />
          </Grid>
        </Grid>


        <Grid container spacing={2} className={classes.tableContainer} data-aos="fade-up" data-aos-duration="1500">

          <Grid item lg={12} xs={12} md={12} >
            <AllCandidatesForEmployer />

          </Grid>
        </Grid>


        <Grid container spacing={2} data-aos="fade-up" data-aos-duration="1500" >

          <Grid item lg={6} xs={12} className={classes.adminSecondRow}>
            <JobCards />
          </Grid>
          <Grid item lg={6} xs={12} className={classes.adminSecondRow}>
            <RecentActivities />
          </Grid>
        </Grid>


      </Container>
    </div>





  }
  //  else if (page == Ways.employerJobs) {
  //   content = <div></div>
  // }
  else if (page == '/dashboard/myaccount') {
    content = <div></div>
  } else if (page == '/dashboard/candidateprofile') {
    content = <div></div>
  } else if (page.includes(Ways.employerAllChats)) {
    content = <EmployerChats />
  } else if (page == Ways.employerJobs) {
    content = <EmployerAllJobsPage />
  }
  
  else if (page.includes('employer/job') && page.includes('applicants')) {
    content = <AllCandidatesForJob />
  }
  else if (page.includes(Ways.employerSingleJob)) {
    content = <EmployerSingleJob />
  }
  else if (page == Ways.postaJob) {

    content = <PostJob />
  } else if (page == Ways.employerCandidates) {
    content = <CandidatesTable />
  } else if (page.includes(Ways.candidateProfilePageForEmployer)) {
    content = <SingleCandidatePage email="" />
  } else if (page == '/employer/dashboard/account') {
    content = <MyAccountLayout />
  } else if (page == Ways.employerAccountEditPage) {
    content = <SignupForm />
  }



  return (
    <div className={classes.root}>


      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar} style={{ backgroundColor: '#F7F7F7' }}>

        <TitleBar onOpenClick={() => {
          handleDrawerToggle();
        }} />



      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        {window.location.pathname == '/employer/dashboard/account' && <StickyHeader />}

        {/* 
        <Container maxWidth='xl'> */}

        {/* <Grid container > */}

        {/* <Grid item lg={12} className={classes.boxesGridStyle}> */}
        {content}
        {/* </Grid> */}
        {/* </Grid> */}
        {/* </Container> */}
      </main>
    </div>
  );
}