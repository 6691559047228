import { AppBar, Button, CircularProgress, Dialog, DialogContent, Grid, List, ListItem, MenuItem, Select, TextField, Toolbar, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Axios from 'axios';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { read_cookie } from 'sfcookies';
import { apihost, LangHelper, Lafz } from '../../Helper';
import { PopupForm } from './PopupForm';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        customBadge: {
            backgroundColor: "#41d18c",
            color: "white",
            marginTop: 15,
            marginRight: 15,
            width: 10,
            height: 10,
            border: '2px solid rgb(255, 255, 255)',
        },
        imageGrid: {
            marginTop: 15,
        },
    }),
);


export const MemberEdit: FunctionComponent<{ member: {} }> = props => {
    const [name, setName] = props.member['name'];
    const [email, setEmail] = props.member['email'];
    const [role, setRole] = props.member['role'];
    const [password, setPassword] = props.member['password'];

    return <div>
        <TextField label='Name' value={name} onChange={e => setName(e.target.value)} />
        <TextField label='Email' value={email} onChange={e => setEmail(e.target.value)} />
        <TextField label='Password' value={password} onChange={e => setPassword(e.target.value)} />
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            onChange={val =>
                setRole(val.target['value'])
            }
            fullWidth
        >
            <MenuItem value={'director'}>Director</MenuItem>
            <MenuItem value={'manager'}>Manager</MenuItem>
            <MenuItem value={'assistant'}>Assistant</MenuItem>
            <MenuItem value={'developer'}>Developer</MenuItem>
        </Select>
    </div>
}

export const AllMembers: FunctionComponent<{members?:any}> = props => {
    const [members, setMembers] = useState();
    const [job, setJob] = useState({})
    const [isPermissionOpen, setIsPermissionOpen] = useState(false);
    const [currentMember, setCurrentMember] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [isMemberOpen, setIsMemberOpen] = useState(false);

    let id: number = 1
    const getID = () => {
        return id++;
    }

    const getData = () => {
        Axios.get(apihost + '/employer/members', { headers: { employer: read_cookie('data')['id'] } }).then(x => {
            if (x.status == 200) {
                console.log(x.data);
                setMembers(x.data);
            }
        });
    }
    useEffect(() => {
        getData();

    }, []);
    if (members == null) {
        return <div style={{ textAlign: 'center' }}>
            <CircularProgress />
        </div>
    }
    return <div style={{ height: '100vh', }}>
        <Grid container direction="row" justify="flex-end">
            <Grid item>
                <br />
                <Button variant="outlined" color="primary" onClick={e=> {
                    setIsMemberOpen(true)
                }} >
                    Add Member
                 </Button>
            </Grid>
        </Grid><br />
        <AppBar position="static" style={{ backgroundColor: '#ffffff', boxShadow: 'none', borderTopLeftRadius: 12, borderTopRightRadius: 12, paddingTop: 25, }}>
            <Toolbar>
                <Typography variant="h6" style={{ color: '#202020' }}>
                    All Members
                  </Typography>
            </Toolbar>
        </AppBar>


        <MaterialTable
            style={{ boxShadow: 'none', backgroundColor: '#ffffff', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, }}
            title=''
            columns={[
                { title: 'Id', render: () => <div>{getID()}</div> },
                { field: 'agent', title: 'Name' },
                { field: 'email', title: 'Email' },
                { title: 'Role', field: 'role' },



                {
                    title: 'Action', render: (rd) => {
                        return (
                            <List style={{ display: 'flex', flexDirection: 'row', }}>
                                <ListItem>
                                    <Button variant='outlined' color='primary' size="small" onClick={() => {
                                        var r = rd;
                                        r['name']= rd['agent']
                                        setCurrentMember(r);
                                        setIsMemberOpen(true);
                                    }}>View</Button>
                                </ListItem>
                                <ListItem style={{ marginLeft: -20, }}>
                                    <Button variant='outlined' color='secondary' size="small" onClick={async () => {
                                        // await Axios.delete(apihost + '/employer/member', { headers: { member: rd['_id'] } });
                                        // enqueueSnackbar('Member Deleted')
                                        // getData();
                                        setCurrentMember(rd);
                                        setIsPermissionOpen(true);
                                    }}>Delete</Button>
                                </ListItem>
                            </List>
                        )
                    }
                }]}
            data={members}

        />


        <Dialog open={isPermissionOpen}>
            <DialogContent >
                <Typography variant='body1'>Are you sure you want to delete ?</Typography>
                <List style={{ display: 'flex', flexDirection: 'row', }}>
                    <ListItem style={{ marginLeft: -20, }}>
                        <Button variant='outlined' color='secondary' size="small" onClick={async () => {
                            await Axios.delete(apihost + '/employer/member', { headers: { member: currentMember['_id'] } });
                            enqueueSnackbar('Member Deleted')
                            getData();
                            setCurrentMember({})
                            setIsPermissionOpen(false);
                        }}>Delete</Button>
                    </ListItem>
                    <ListItem style={{ marginLeft: -20, }}>
                        <Button variant='outlined' color='secondary' size="small" onClick={async () => {
                            // await Axios.delete(apihost + '/employer/member', { headers: { member: rd['_id'] } });
                            // enqueueSnackbar('Member Deleted')
                            // getData();
                            setCurrentMember({});
                            setIsPermissionOpen(false);
                        }}>Cancel</Button>
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>

        <Dialog open={isMemberOpen} onClose={()=>setIsMemberOpen(false)}>
            <DialogContent>
                <PopupForm isOpen={isMemberOpen} member={currentMember} onClose={()=>{
                    setIsMemberOpen(false);
                    setCurrentMember(null)
                }}/>
            </DialogContent>
        </Dialog>
    </div>



}