import { AppBar, Box, Button, Card, Container, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField, Toolbar, Typography, Link, CircularProgress } from '@material-ui/core';
import { WorkOutline } from '@material-ui/icons';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { API, apihost, Ways } from '../Helper';
import { PostJobTitleBar } from './PostJobTitleBar';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { createBrowserHistory } from 'history';

const UseNewStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '-9%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      marginTop: '-28%',
    },
  },

  LoginContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 15,
    marginTop: '-10',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      marginTop: '-28%',
    },



  },


  topBar: {

    backgroundColor: 'transparent',
    boxShadow: 'none',
    // paddingTop: 90,

    alignItems: 'stretch',

  },

  btnStyle: {
    color: '#ffffff',
    borderColor: '#efefef',
    '&:hover': {

      color: '#efefef',
      borderColor: '#efefef',



    },
  },

  iconStyle: {
    fontSize: 35,

  },


  menuButton: {
    marginRight: theme.spacing(2),

  },
  title: {
    flexGrow: 1,
    //   display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  link: {

    textDecoration: 'none',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#4B4A6C',
  },
}));

export const PostJob = () => {
  const classes = UseNewStyles({});

  const { enqueueSnackbar } = useSnackbar();

  const [companyUrl, setCompanyUrl] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('')
  const [jobShortDescription, setShortDes] = useState<string>('');
  const [jobLongDescription, setLongDes] = useState('');
  const [salary, setSalary] = useState('');
  const [location, setLocation] = useState('');
  const [time, setTime] = useState();
  const [vacancies, setVacancies] = useState('');
  const [complete , setComplete] = useState(null);

  const getData = async () => {
    var a = await Axios.get(apihost +'/employer/isProfileComplete' , {headers : {employer : API.getCookieData()['id']}});
    setComplete(a.data);
  }

  useEffect(() => {
    getData()
  }, []);


  if(complete == null){
    return <Box>
      <Card style={{margin : '40%' , padding : '3%' , alignItems : 'center' , flex : 'center' , display : 'flex'}}>
        <CircularProgress/>
      </Card>
    </Box>
  }

  if(complete == false){
    return <Box>
    <Card style={{margin : '40%' , padding : '3%' ,}}>
      <Typography variant='body1'><Link href={Ways.employerAccountEditPage}>Complete Your profile </Link> to post a Job</Typography>
    </Card>
  </Box>
  }

  return (
    <Box>
      <PostJobTitleBar />
      <Container className={classes.root}>
        <Grid item lg={12} xs={12} >

          <AppBar position="static" className={classes.topBar}>
            <Toolbar>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <WorkOutline className={classes.iconStyle} />
              </IconButton>
              <Typography variant="h4" className={classes.title}>
                Post A Job
      </Typography>
              <Button variant="outlined" className={classes.btnStyle}>Save</Button>
            </Toolbar>
          </AppBar>
        </Grid>


        <Grid container spacing={1} data-aos="fade-up" data-aos-duration="1500">
          <Grid item lg={12} xs={12} style={{ backgroundColor: '#ffffff', padding: 25, marginTop: 15, borderRadius: 15, }}>
            <ValidatorForm
              onSubmit={async e => {
                var empId = JSON.stringify(API.getCookieData()['id']);
                var a: string[] = [
                  jobTitle, jobShortDescription, jobLongDescription, salary, time, vacancies
                ];


                if (!a.every(x => x != null)) {
                  alert('Invalid Values');
                  return
                };
                var job = {
                  jobTitle: jobTitle,
                  jobShortDescription: jobShortDescription,
                  jobLongDescription: jobLongDescription,
                  locationText: location,
                  salary: salary,
                  time: time,
                  vacancies: vacancies,
                  userid: empId
                }
                var res = await Axios.post(apihost + '/employer/postajob', job);
                if (res.status == 200) {
                  enqueueSnackbar("Job Posted");
                  createBrowserHistory().push(Ways.employerJobs);
                  window.location.reload();
                } else if (res.status == 202) {
                  enqueueSnackbar("Please complete profile before posting a job");
                }
                else {
                  enqueueSnackbar("There was an error");

                }
              }}

            >
              <Typography variant="h6">Job Details</Typography>
              <TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={jobTitle}
                id="jobtitle"
                // label="Email Address"
                name="Title"
                onChange={e => setJobTitle(e.target['value'])}
                label="Job Title"
                autoFocus
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 ]*$']}
                errorMessages={['this field is required', 'Job Title is in an invalid format']}
              />
              <TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={jobShortDescription}
                onChange={e => setShortDes(e.target['value'])}
                name="Short Description"
                // label="Password"
                type="text"
                id="text"
                label="Short Description"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 .,]+$']}
                errorMessages={['this field is required', 'Short Description is in an invalid format']}

              // autoComplete="current-password"
              />

              <TextValidator
                variant="outlined"
                id="outlined-multiline-static"
                multiline
                margin="normal"
                required
                fullWidth
                value={jobLongDescription}
                rows={4}
                onChange={e => setLongDes(e.target['value'])}
                name="Long Description"
                rowsMax={8}
                // label="Password"
                type="text"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 .,]+$']}
                errorMessages={['this field is required', 'Long Description is in an invalid format']}

                label="Long Description"
              // autoComplete="current-password"
              />






              <TextValidator
                variant="outlined"
                margin="normal"
                id='salary'
                value={salary}
                required
                fullWidth
                name="Sallery"
                placeholder={'$'}
                onChange={e => setSalary(e.target['value'])}
                // label="Password"
                type="text"
                label="Sallery"
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'Salary is in an invalid format']}

              // autoComplete="current-password"
              />



              {/* <TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id='location'
                value={location}
                name="Location"
                onChange={e => setLocation(e.target['value'])}
                type="text"
                label="Location"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,]+$']}
                errorMessages={['this field is required', 'Location is in an invalid format']}

            
              /> */}


              <TextValidator
                id="outlined-select-currency-native"
                select
                label="Job Type"
                fullWidth
                required
                name='jobtype'
                margin="normal"
                variant="outlined"
                value={time}
                validators={['required']}
                errorMessages={['this field is required']}

                onChange={x => {
                  setTime(x.target['value'])
                }}
              >
                <MenuItem value={10}>Part Time</MenuItem>
                <MenuItem value={20}>Full Time</MenuItem>
              </TextValidator>

              <TextValidator
                id="outlined-select-currency-native"
                select
                label="Vacancies"
                onChange={e => setVacancies(e.target['value'])}
                fullWidth
                margin="normal"
                value={vacancies}
                name='vacancies'
                variant="outlined"
                required
                validators={['required']}
                errorMessages={['this field is required']}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </TextValidator>
<Typography variant="h6">Address</Typography>
              <Grid container spacing={2}>
<Grid item lg={6}>
<TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id='location'
                value={location}
                name="Location"
                onChange={e => setLocation(e.target['value'])}
                // label="Password"
                type="text"
                label="address 1"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,]+$']}
                errorMessages={['this field is required', 'Location is in an invalid format']}

              // autoComplete="current-password"
              />
              </Grid>
<Grid item lg={6}>
<TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id='location'
                value={location}
                name="Location"
                onChange={e => setLocation(e.target['value'])}
                // label="Password"
                type="text"
                label="Adress 2"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,]+$']}
                errorMessages={['this field is required', 'Location is in an invalid format']}

              // autoComplete="current-password"
              />
              </Grid>

              <Grid item lg={6}>
<TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id='location'
                value={location}
                name="Location"
                onChange={e => setLocation(e.target['value'])}
                // label="Password"
                type="text"
                label="City"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,]+$']}
                errorMessages={['this field is required', 'Location is in an invalid format']}

              // autoComplete="current-password"
              />
              </Grid>
<Grid item lg={6}>
<TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id='location'
                value={location}
                name="Location"
                onChange={e => setLocation(e.target['value'])}
                // label="Password"
                type="text"
                label="State"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,]+$']}
                errorMessages={['this field is required', 'Location is in an invalid format']}

              // autoComplete="current-password"
              />
              </Grid>

              <Grid item lg={12}>
             

<TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id='location'
                value={location}
                name="Location"
                onChange={e => setLocation(e.target['value'])}
                // label="Password"
                type="text"
                label="Zip Code"
                validators={['required', 'matchRegexp:^[a-zA-Z0-9 ,]+$']}
                errorMessages={['this field is required', 'Location is in an invalid format']}

              // autoComplete="current-password"
              />
              
              </Grid>

              </Grid>




              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={async e => {
                }}
              >
                Post Job
          </Button>

            </ValidatorForm>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
};






const useStyles = makeStyles({
  postJobPage: {
    minHeight: '100vh',
    backgroundColor: '#FBFBFB',
  },

  form: {

    backgroundColor: '#ffffff',
  },
  Card: {
    padding: 50,
    marginTop: 30,
  },
  loginFormFields: {
    marginTop: 10,
    marginBottom: 10,
  },
  topSpace: {
    marginTop: 30,
  },
  h2: {
    fontWeight: 800,
    fontSize: 40,
  },

});



export class Job {
  host?: string;
  companyUrl?: string;
  companyName?: string;
  companyLogo?: string;
  jobUrl?: string;
  jobTitle?: string;
  jobShortDescription?: string;
  locationText?: string;
  jobLongDescription?: string;
  applyLink?: string;
  salary?: string;
  time?: string;
  vacancies?: string;
}



// Nothing below this is usable


export const PostAJobPage: FunctionComponent = props => {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();

  const [companyUrl, setCompanyUrl] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('')
  const [jobShortDescription, setShortDes] = useState('');
  const [jobLongDescription, setLongDes] = useState('');
  const [salary, setSalary] = useState();
  const [location, setLocation] = useState('');
  const [time, setTime] = useState();
  const [vacancies, setVacancies] = useState();






  return <Container>

    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.postJobPage}>
      {/* <Grid item lg={12}><Typography variant="h2" component="h2" className={classes.h2}>Post a job</Typography></Grid> */}
      <Grid item lg={8}>
        <Card className={classes.Card} elevation={0}>
          <Grid container>

            <Grid item lg={12}>
              <TextField variant='outlined' className={classes.loginFormFields} fullWidth label='Job Title' onChange={e => setJobTitle(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <TextField variant='outlined' className={classes.loginFormFields} fullWidth label='Short Description' onChange={e => setShortDes(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <TextField variant='outlined' className={classes.loginFormFields} fullWidth label='Long Description' onChange={e => setLongDes(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <TextField variant='outlined' className={classes.loginFormFields} fullWidth label='Salary' onChange={e => setSalary(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <TextField variant='outlined' className={classes.loginFormFields} fullWidth label='Location' onChange={e => setLocation(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <FormControl fullWidth className={classes.loginFormFields}>
                <InputLabel >Job Type</InputLabel>
                <Select
                  labelWidth={20}
                  value={time}
                  onChange={x => {
                    setTime(x.target.value)
                  }}>
                  <MenuItem value='Part Time'>Part Time</MenuItem>
                  <MenuItem value='Full Time'>Full Time</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12}>
              <TextField variant='outlined' fullWidth className={classes.loginFormFields} label='Vacancies' type='number' onChange={e => setVacancies(e.target['value'])}></TextField>
            </Grid>
            <Grid item lg={12}>
              <Button variant='contained' onClick={e => {


                return;
                var empId = JSON.stringify(API.getCookieData()['id']);
                var a: string[] = [
                  jobTitle, jobShortDescription, jobLongDescription, salary, time, vacancies
                ];
                if (!a.every(x => x != null)) return;
                var job = {
                  jobTitle: jobTitle,
                  jobShortDescription: jobShortDescription,
                  jobLongDescription: jobLongDescription,
                  locationText: location,
                  salary: salary,
                  time: time,
                  vacancies: vacancies,
                  userid: empId
                }
                Axios.post(apihost + '/employer/postajob', job).then(x => {
                  alert("POSTED")
                  console.log(JSON.stringify(x.data));
                  enqueueSnackbar("Job Posted");
                })
              }}>Post Job</Button>
            </Grid>
          </Grid>

        </Card>
      </Grid>

    </Grid>
  </Container>


}