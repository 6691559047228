import React, { Component, FunctionComponent, useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import firebase from 'firebase';
import { API } from '../../Helper';
import { register } from '../../serviceWorker';
import { render } from 'react-dom';








class UploadImage extends Component<{ onUploaded: (x: string) => void, image: string }, {}> {


    state = {
        file: '',
        imagePreviewUrl: ''
    };

    _handleSubmit = e => {
        e.preventDefault();
        // TODO: do something with -> this.state.file
    }

    _handleImageChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({ file: file, imagePreviewUrl: reader.result });
            var ref = firebase.storage().ref(API.getCookieData()['id'] + '/logo')
            var uploadTask = ref.put(file);
            uploadTask.then(async x => {

                this.props.onUploaded(await ref.getDownloadURL());
            })

        }
        reader.readAsDataURL(file)
    }

    render() {
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;



        return (
            <div>
                {!$imagePreview && <img src={imagePreviewUrl} style={{ border: '1px solid #efefef', overflow: 'hidden', maxHeight: 200, maxWidth: 250, }} />}
                <form onSubmit={this._handleSubmit}>
                    <input accept="image/*"
                        className="custom-file-input"

                        id="icon-button-file"
                        multiple
                        type="file" onChange={this._handleImageChange} />

                    {/* <Button variant="contained" type="submit" color="primary" onClick={this._handleSubmit}>Upload Logo</Button> */}
                </form>

            </div>
        )
    }

}



export const NewUploadImage: FunctionComponent<{ onUploaded: (x) => void, image: string }> = props => {


    const [file, setFile] = useState('');
    const [imagePreview, setImagePreview] = useState('')

    useEffect(() => {
        setImagePreview(props.image);
    }, [])

    const _handleSubmit = e => {
        e.preventDefault();
        // TODO: do something with -> this.state.file
    }

    const _handleImageChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            // this.setState({ file: file, imagePreviewUrl: reader.result.toString() });
            setFile(file);
            //setImagePreview(render.result.toString());
            var ref = firebase.storage().ref(API.getCookieData()['id'] + '/logo')
            var uploadTask = ref.put(file);
            uploadTask.then(async x => {
                var downloadUrl = await ref.getDownloadURL();
                props.onUploaded(downloadUrl);
                setImagePreview(downloadUrl);
            })

        }
        reader.readAsDataURL(file)
    }
    if (imagePreview.length == 0 && props.image != undefined && props.image.length > 0) {
        console.log('Setting image');
        setImagePreview(props.image);
    }

    return (
        <div>
            {imagePreview.length > 0 && <img src={imagePreview} style={{ border: '1px solid #efefef', overflow: 'hidden', maxHeight: 200, maxWidth: 250, }} />}
            <form onSubmit={_handleSubmit}>
                <input accept="image/*"
                    className="custom-file-input"

                    id="icon-button-file"
                    multiple
                    type="file" onChange={_handleImageChange} />

                {/* <Button variant="contained" type="submit" color="primary" onClick={this._handleSubmit}>Upload Logo</Button> */}
            </form>

        </div>
    );

}




export default UploadImage;