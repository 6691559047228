import * as React from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import { Button, IconButton, Icon, Dialog, DialogContent, AppBar, Toolbar, Grid, Card, CircularProgress, colors, Breadcrumbs, Link, Typography } from '@material-ui/core';
import { Delete, Close, PanoramaFishEye, Visibility } from '@material-ui/icons';
import { createBrowserHistory } from 'history';
import { apihost, Ways } from '../Helper';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

interface JobsTableProps {
}

export const FullScreenLoading: React.FunctionComponent = props => {
  return <div >
    <CircularProgress style={{ marginLeft: '45%', marginRight: '45%', marginBottom: '200px', marginTop: '200px' }} />
  </div>
}

export const JobsTable: React.FunctionComponent<JobsTableProps> = (props) => {

  let a: any[] = Array.of<any>();
  const [data, setData] = React.useState(a);
  const [isOpen, setIsOpne] = React.useState(false);
  const [cDoc, setCDoc] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(true);


  const getData = async () => {
    setIsLoading(true);
    var d = await axios.get(apihost + '/jobs');
    console.log(d.data)
    setData(d.data['jobs']);
    setIsLoading(false);

  }
  React.useEffect(() => {
    getData();
  }, [])
  if (isLoading) {
    return <FullScreenLoading/>
  } else {
    return <div>
      <Grid>
        <Button onClick={async x => {
          setIsLoading(true);

          var d = await axios.get(apihost + '/jobs');
          console.log(d.data)
          setData(d.data['jobs']);
          setIsLoading(false);

        }}>All Jobs</Button>
        <Button onClick={async x => {
          setIsLoading(true);

          var d = await axios.get(apihost + '/ourjobs');
          console.log(d.data)
          setData(d.data['jobs']);
          setIsLoading(false);

        }}>Registered</Button>
      </Grid>

      <MaterialTable
        title='All Jobs'
        columns={[
          { title: 'Title', field: 'jobTitle' },
          { title: 'Company', field: 'companyName' },
          { title: 'Location', field: 'locationText' },
          { title: 'Summery', field: 'jobShortDescription' },
          {
            title: 'Actions', render: (doc) =>
              <div>
                <IconButton onClick={(x) => {
                  createBrowserHistory().push(Ways.adminSingleJob + doc['_id']);
                  window.location.reload();
                }}><Visibility /></IconButton>
                <IconButton onClick={x => {
                  setCDoc(doc);
                  setIsOpne(true);
                  return;
                }}>
                  <Delete />
                </IconButton>
              </div>
          },
        ]}
        options={{
          pageSize: 20, pageSizeOptions: []
        }} data={data} />
      <Dialog open={isOpen}>
        <Card style={{ padding: "30px" }}>
          <p>Are you sure you want to delete?</p>
          <Grid>
            <ThemeProvider theme={createMuiTheme({
              overrides: {
                MuiButton: {
                  text: {
                    color: 'white'
                  }
                },
              }
            })}>
              <Button style={{ background: 'red', marginRight: '10px' }} onClick={async x => {
                await axios.delete(apihost + '/job', { headers: { jobid: cDoc['_id'] } }).then(x => {
                  if (x.status == 200) {
                    console.log(x.data);
                    var newData = data.filter(x => {
                      console.log(x['_id'] + '   ' + cDoc['_id']);
                      return x['_id'] != cDoc['_id']
                    });
                    setData(newData);
                  }
                });
                setIsOpne(false);
                enqueueSnackbar('Job Listing Deleted', { color: 'red' });
              }}>Delete</Button>
            </ThemeProvider>
            <Button onClick={x => setIsOpne(false)}>Cancel</Button>
          </Grid>
        </Card>
      </Dialog>
    </div >
  }
};

