import { AppBar, Box, Container, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Chat } from '@material-ui/icons';
import Axios from 'axios';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { API, apihost } from '../../Helper';
import { ChatTitleBar } from './ChatTitleBar';
import { Messages } from "./Messages";
import { PersonList } from "./PersonList";
import { Profile } from "./Profile";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: '-8%',

      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
        marginTop: '-28%',
      },
    },

    paper1: {
      padding: theme.spacing(0),
      textAlign: 'center',
      paddingTop: 15,
      height: 610,
      borderRadius: 15,
      boxShadow: 'none',
    },


    iconStyle: {
      fontSize: 35,
    },

    topBar: {
      backgroundColor: '#002448',
      boxShadow: 'none',
      // paddingTop: 90,
      alignItems: 'stretch',
  },
    menuButton: {
      marginRight: theme.spacing(2),
     
    },
    title: {
      flexGrow: 1,
    //   display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },


    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: 610,
      borderRadius: 15,
      boxShadow: 'none',
    },
  }),
);

export const EmployerChats = observer(()=>{

  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);

  useEffect(() => {

    Axios.get(apihost + '/getallchats', { headers: { userid: API.getCookieData()['id'] } }).then(x => {
      console.log(x.data);
      setChats(x.data);
    })

  }, [])
  const classes = useStyles({});
  return (
    <Box>
     <ChatTitleBar />
      <Container className={classes.root}>
      <Grid item lg={12} >
    
    <AppBar position="static" className={classes.topBar}>
    <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
        <Chat className={classes.iconStyle} />
      </IconButton>
      <Typography variant="h4" className={classes.title}>
        All Chats
      </Typography>
      {/* <Button variant="outlined" className={classes.btnStyle}>Add Item</Button> */}
    </Toolbar>
  </AppBar> 
  </Grid>
        <Grid container spacing={1} data-aos="fade-up" data-aos-duration="1500">
          <Grid item lg={3} xs={12}>
            <Paper className={classes.paper1}><PersonList chats={chats} onChatSelected={x => {
            }} /></Paper>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Paper className={classes.paper}><Messages /></Paper>
          </Grid>
          <Grid item lg={3} xs={12}>
            <Paper className={classes.paper}><Profile candidate='simple' /></Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

}) 