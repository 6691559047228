import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import StarRateIcon from '@material-ui/icons/StarRate';
import { Fab, CircularProgress } from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalPlayIcon from '@material-ui/icons/LocalPlay';
import { Container, Grid } from '@material-ui/core';
import { Link } from "react-router-dom"
import 'typeface-roboto';
import Axios from 'axios';
import { apihost, API, Ways } from '../../Helper';
import { StarRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
     displa: 'flex',
     width: 570,
     [theme.breakpoints.down('sm')]:  {
       width: 450,
     },
      flexDirection: 'row',
      marginBottom: '2%',
      paddingTop: 10,

    },

    linkStyle: {

      textDecoration: 'none',
    },
    starIcon: {
      color: '#cecccc',

    },




    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },

    contentStyle: {

      paddingLeft: '12%',
      paddingRight: '15%',
      align: 'justify',
      backgroundColor: 'FAFAFA',
      marginTop: -20,
    },

    avatarStyle:
    {
      backgroundColor: '#f2efef',
      marginTop: 10,
      marginRight: 10,

    },

    mainContainer: {
      backgroundColor: '#ffffff',
      paddingTop: 30,
      paddingBottom: 50,
    },

    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },




    actionIconsStyle: {

      backgroundColor: '#F9F9F9',

    },

    avatar: {
      backgroundColor: red[500],
    },
  }),
);


export const JobCards = () => {
  const classes = useStyles({});
  const [expanded, setExpanded] = React.useState(false);
  const [jobs, setJobs] = React.useState([]);

  useEffect(() => {

    Axios.get(apihost + '/employer/jobs', { headers: { userid: API.getCookieData()['id'] } }).then(x => {
      var lst: any[] = x.data;
      console.log(lst);
      setJobs(lst.slice(0, 3))
    })
  }, []);

  if (jobs.length == 0) {
    return <div style={{ textAlign: 'center' }}>
      <CircularProgress />
    </div>
  }

  return (


    <Container maxWidth="lg" className={classes.mainContainer}>
      <Grid container spacing={2} >
        <Grid item lg={12} xs={12}>
          <Typography variant="h5">
            New Jobs
        </Typography>
        </Grid>

        {/* First Card */}
        {jobs.map((job, i) => {
          var longdes = String(job['jobLongDescription']);
          if (longdes.length > 100) {
            longdes = longdes.substring(0, 150);
          }
          return <div>

            <Grid item lg={12} xs={12}>


              <Link to={Ways.employerSingleJob + job['_id']} className={classes.linkStyle}>
                <Card className={classes.card}>

                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        R
  </Avatar>
                    }
                    action={
                      // <Fab size="small" aria-label="like">
                      <Avatar className={classes.avatarStyle}>
                        <StarRounded className={classes.starIcon} />
                      </Avatar>
                      // </Fab>
                    }
                    title={job['jobTitle']}
                    subheader={job['employerName']}
                  />
                  {/* <CardMedia
className={classes.media}
image="/static/images/cards/paella.jpg"
title="Paella dish"
/> */}
                  <CardContent className={classes.contentStyle}>

                    <Typography  variant="body2" color="textSecondary" component="p" align="justify">
                      {longdes}
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.actionIconsStyle} disableSpacing >
                    {/* <IconButton aria-label="add to favorites"> */}
                    <IconButton disabled>
                      <LocationOnIcon />
                    </IconButton>
                    <Typography variant="caption">{job['locationText']}</Typography>


                    <IconButton disabled>
                      <LocalPlayIcon />
                    </IconButton>
                    <Typography variant="caption">{job['time']}</Typography>


                    <IconButton disabled>
                      <CreditCardIcon />
                    </IconButton>
                    <Typography variant="caption">{job['salary']}</Typography>


                    <IconButton disabled>
                      <QueryBuilderIcon />
                    </IconButton>
                    <Typography variant="caption">uploaded recently</Typography>

                  </CardActions>



                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>

                    </CardContent>
                  </Collapse>
                </Card>
              </Link>
              {/* Second Card */}
            </Grid>
          </div>
        })}

      </Grid>
    </Container>)

}