import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { MoreHoriz } from '@material-ui/icons';
import { Container, Grid, Divider, Stepper, Step, StepLabel, StepContent, Box, Avatar, IconButton  } from '@material-ui/core';
import 'typeface-roboto';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    summaryContainer: {


        backgroundColor: '#ffffff',
        
    },

        portfolioImg: {

            width: 200,
            height: 220,
        },
 


    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },


    btnLanguages: {

            display: 'flex',
            flexDirection: 'row',

    },

    AvatarIcons: {
        display: 'flex',
        flexDirection: 'row',
        
    },

    mainContainer: {
        backgroundColor: '#ffffff',
        paddingTop: 30,
    },


    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
        stepperIcon: {
            color: '#efefef',
        },

        smallavatar: {

            marginLeft: 5,
            marginTop: -10,
        },

    actionIconsStyle: {

        backgroundColor: '#e2e0e0',
    },


    avatar: {
      backgroundColor: red[500],
    },
  }),
);

export const RecentActivities = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);


  return (

<Box>
    <Container maxWidth="lg" className={classes.mainContainer}>
    <Grid container spacing={2} >

      {/* First Card */}

{/* 
      <Grid item lg={12} xs={12}>
          <Typography variant="h6" align="left" gutterBottom>
              Education And Experience
          </Typography>
      </Grid> */}
      
     <Grid item lg={6} xs={6}>
         <Typography variant="h5">Recent Activities</Typography>
         </Grid>
         <Grid item lg={5} xs={5}>
         
         </Grid>
         <Grid item lg={1} xs={1}>
             <IconButton>
         <MoreHoriz />
         </IconButton>
         </Grid>
        <Grid item lg={12} xs={12}>
            <Divider />
        </Grid>
      <Grid item lg={12} xs={12}>
      
      <Stepper orientation="vertical" >
    <Step active >
    <StepLabel>
        10:00
    </StepLabel>

    <StepContent>
    {/* <Typography variant="h6" gutterBottom>Work Experience</Typography> */}
     
     {/* <Typography variant="body1" gutterBottom>Present-2015</Typography>
     <Typography variant="subtitle2" gutterBottom>Product Manager</Typography> */}
     <Typography variant="caption" gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
     <Typography variant="caption" gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>

    </StepContent>
    </Step>

    <Step active>
    <StepLabel>
        12:45
    </StepLabel>

    <StepContent>
    <div className={classes.AvatarIcons}>
      <Typography variant="subtitle2" gutterBottom>AEOL Meeting With    </Typography>
      
      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.smallavatar} />
      <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" className={classes.smallavatar} />
      <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" className={classes.smallavatar} />
      <Avatar alt="Jimmy Fallen" src="/static/images/avatar/4.jpg" className={classes.smallavatar} />
      </div>
     
    </StepContent>
    </Step>


    
    <Step active>
    <StepLabel>
        14:00
    </StepLabel>

    <StepContent>
      <Typography variant="subtitle2" gutterBottom>Make Deposit $700 to ESL.</Typography>
    </StepContent>
    </Step>




    <Step active>
    <StepLabel>
    16:15
    </StepLabel>
       
    <StepContent>
  
      <Typography variant="caption" gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
      
    </StepContent>
    </Step>



    <Step active>
    <StepLabel>
    18:00
    </StepLabel>
        
    <StepContent>
    
      <Typography variant="subtitle2" gutterBottom>Placed A New Order In Signature Market Place.</Typography>
  
    </StepContent>
    </Step>




    <Step active>
    <StepLabel>
        19:30
    </StepLabel>

    <StepContent>
  
      <Typography variant="caption" gutterBottom>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>

    </StepContent>
    </Step>


    
    <Step active>
   

    <StepContent>
   
    </StepContent>
    </Step>



    
</Stepper>
        
      </Grid>


                                      
            

    </Grid>
</Container>
</Box>





  );
}