import { useEffect, useState } from 'react';
import React from 'react';
import { Grid, CircularProgress, Button, Container, AppBar, Toolbar, IconButton, Box, Typography, Link, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { LocationOn, Payment, AccessTime, WorkOutline } from '@material-ui/icons';
import Axios from 'axios';
import { apihost } from '../Helper';
import { EditableText, EditableSelect } from '../TComp';
import { useSnackbar } from 'notistack';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import lion from './../assets/lion.jpg'
import { SingleJobTitleBar } from './SingleJobTitleBar';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: '-10%',


            [theme.breakpoints.down('sm')]: {
                flexGrow: 1,
                marginTop: '-28%',
            },
        },

        mainContainer: {

            backgroundColor: '#ffffff',
            padding: 50,
            minHeight: 600,
            borderRadius: 15,
            marginTop: -50,
            width: '93%',

        },


        h2: {
            color: '#006999',
            fontWeight: 600,
            fontSize: 30,
            paddingTop: 10,
            padddingBottom: 20,
        },
        h3: {
            fontSize: 28,
            fontWeight: 600,
            paddingTop: 10,
            padddingBottom: 20,
        },

        companyLogo: {

            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#FBFBFB',
            width: 100,
            height: 100,
        },


        jobTitles: {

            paddingLeft: 20,
            paddingRight: 20,
        },


        List: {
            '& .MuiListItem-root': {
                paddingTop: 3,
                paddingBottom: 3,
                '& .MuiListItemIcon-root': {
                    minWidth: 25,
                },
            },

        },


        summary: {
            paddingTop: 30,
            '& textarea': {
                width: '100% !important',
            },
        },

        textarea: {
            width: '100%',
        },

        iconStyle: {

            color: '#ffffff',
            fontSize: 35,

        },

        topBar: {

            backgroundColor: '#002448',
            boxShadow: 'none',
            // paddingTop: 90,

            alignItems: 'stretch',

        },




        menuButton: {
            marginRight: theme.spacing(2),

        },
        title: {
            flexGrow: 1,
            //   display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },



    }),
);




export const EmployerSingleJob: React.FunctionComponent = (props) => {
    const classes = useStyles(props);
    var ar = window.location.pathname.split('/');
    const [cJob, setJob] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [shortDes, setShortDes] = useState()
    const [longDes, setLongDes] = useState()
    const [vacancies, setVacancies] = useState();
    const [salary, setSalary] = useState()
    const [time, setTime] = useState();
    const [location, setLocation] = useState();
    const [jobTitle,setJobTitle] = useState();
    const [] = useState([]);

    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        if (ar.length > 2) {
            Axios.get(apihost + '/job/' + ar[3]).then(x => {
                setLongDes(x.data['jobLongDescription'])
                setShortDes(x.data['jobShortDescription']);
                setSalary(x.data['salary']);
                setTime(x.data['time'])
                setVacancies(x.data['vacancies'])
                setLocation(x.data['locationText']);
                setJobTitle(x.data['jobTitle'])
                setJob(x.data);

                console.log(x.data);
            })

            // Axios.get(apihost + '/employer/applicants', { headers: { job: ar[3] } }).then(x => {
            //     setApplicants(x.data);
            // })
        }
    }, []);
    if (ar.length < 3) {
        return <div>Invalid Url</div>
    } else if (cJob == null) {
        return <div >
            <CircularProgress style={{ marginLeft: '45%', marginRight: '45%', marginBottom: '200px', marginTop: '200px' }} />
        </div>
    }
    else {
        return <Box>
            <SingleJobTitleBar />

            <Container className={classes.root} data-aos="fade-up" data-aos-duration="1500">


                <Grid item lg={12} xs={12} >
                    <AppBar position="static" className={classes.topBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" aria-label="menu">
                                <WorkOutline className={classes.iconStyle} />
                            </IconButton>
                            <Typography variant="h4" className={classes.title} >
                                All Jobs
                        </Typography>

                            {/* <Button variant="outlined" className={classes.btnStyle}>Add Item</Button> */}
                        </Toolbar>
                    </AppBar>
                </Grid>


                <Grid container style={{ backgroundColor: '#ffffff', padding: '5%', borderRadius: 15, }}>

                    {/* <Grid item lg={1} xs={12}>

                        <img src={lion} alt="lion logo" className={classes.companyLogo} />

                    </Grid>



                    <Grid item lg={4} className={classes.jobTitles} xs={12}>
                        <Typography variant="h6" component="h6" className={classes.h2}>{cJob['jobTitle']}</Typography>
                        <Link href={cJob['companyUrl']}>{cJob['companyName']}</Link>

                    </Grid>



                    <Grid item lg={1} xs={1}>

                    </Grid> */}


                    <Grid lg={10} xs={12}>
                        <div>
                        <Typography variant="body2"  >Job Title</Typography>
                        {<EditableText wide  text={jobTitle} onChange={x => { setJobTitle(x) }} isEditing={isEditing} />}


                            <Typography variant="body2"  >Location</Typography>

                            {<EditableText wide  text={location} onChange={x => { setLocation(x) }} isEditing={isEditing} />}
                            <Typography variant="body2"  >Salary</Typography>

                            {<EditableText wide  text={salary} onChange={x => { setSalary(x) }} isEditing={isEditing} />}
                            <Typography variant="body2"  >Vacancies</Typography>

                            {<EditableText wide  text={vacancies} onChange={x => { setVacancies(x) }} isEditing={isEditing} />}
                            <Typography variant="body2"  >Job Type</Typography>

                            {<EditableSelect wide value={time} onChange={x => { setTime(x) }} options={['Full Time', 'Part Time']} isEditing={isEditing} />}
                        </div>

                        {/* <List className={classes.List}>
                            <ListItem>
                                <ListItemIcon>
                                    <LocationOn className={classes.iconStyle} />
                                </ListItemIcon>
                                <ListItemText>

                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Payment className={classes.iconStyle} />
                                </ListItemIcon>
                                <ListItemText>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AccessTime className={classes.iconStyle} />
                                </ListItemIcon>
                                <ListItemText>
                                </ListItemText>
                            </ListItem>
                        </List> */}

                    </Grid>



                    <Grid lg={2} xs={12}>
                        {!isEditing && <Button variant='outlined' color="primary" onClick={() => setIsEditing(true)}>Edit Job</Button>}
                        {isEditing && <Button variant='outlined' onClick={async () => {
                            var j = cJob as {};
                            j['jobLongDescription'] = longDes;
                            j['jobShortDescription'] = shortDes;
                            j['time'] = time;
                            j['locationText'] = location;
                            j['salary'] = salary;
                            j['vacancies'] = vacancies;

                            await Axios.put(apihost + '/job', j);
                            enqueueSnackbar("Changes Saved.")
                            setIsEditing(false);
                        }}>Save Changes</Button>}
                    </Grid>




                    <Grid item lg={10}>
                        <div className={classes.summary}>
                            <Typography variant="body2"  >Short Description</Typography>
                            <EditableText wide text={shortDes} rows={2} onChange={x => { setShortDes(x) }} isEditing={isEditing} />

                            <Typography variant="body2"  >Long Description</Typography>
                            <EditableText wide text={longDes} rows={5} onChange={x => { setLongDes(x) }} isEditing={isEditing} />
                        </div>
                    </Grid>


                </Grid>


            </Container>


        </Box>

    }
}