import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Group, Work, AccountBox, RecentActors } from '@material-ui/icons';
import 'typeface-roboto';

const useStyles = makeStyles({
  card: {
    backgroundColor: '#CCAC00',
    color: '#ffffff',
    display: 'flex',
    padding: 3,


  },

  card2: {
    backgroundColor: '#46d246',
    color: '#ffffff',
    display: 'flex',
    padding: 3,
  },

  card3: {

    backgroundColor: '#7b68ee',
    color: '#ffffff',
    display: 'flex',
    padding: 3,
  },


  card4: {

    backgroundColor: '#ff7d66',
    color: '#ffffff',
    display: 'flex',
    padding: 3,
  },
  iconStyle: {
    color: '#ffffff',
    fontSize: '70',
    opacity: '40%',
  },

  headingStyle: {

    color: '#ffffff',

  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
  },


  title: {
    fontSize: 20,
    color: '#ffffff',
    opacity: '50%',
  },





  //   pos: {
  //     marginBottom: 12,
  //   },
});

export const LayoutCards = () => {
  const classes = useStyles();


  return (


    <Grid container className={classes.cardContainer} spacing={2}>


      <Grid item lg={4} xs={12}>

        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.headingStyle} variant="h5" color="primary" gutterBottom>
              546
        </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              CANDIDATES
        </Typography>

          </CardContent>
          <CardActions>
            <Group fontSize="large" className={classes.iconStyle} />
            {/* <Button size="small">Learn More</Button> */}
          </CardActions>
        </Card>
      </Grid>







      <Grid item lg={4} xs={12} >
        <Card className={classes.card3}>
          <CardContent>

            <Typography className={classes.headingStyle} variant="h5" color="primary" gutterBottom>
              123
        </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              JOBS
  </Typography>

          </CardContent>
          <CardActions>
            <Work fontSize="large" className={classes.iconStyle} />
            {/* <Button size="small">Learn More</Button> */}
          </CardActions>
        </Card>
      </Grid>







      <Grid item lg={4} xs={12} >
        <Card className={classes.card3}>
          <CardContent>

            <Typography className={classes.headingStyle} variant="h5" color="primary" gutterBottom>
              123
        </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Employers
  </Typography>

          </CardContent>
          <CardActions>
            <AccountBox fontSize="large" className={classes.iconStyle} />
            {/* <Button size="small">Learn More</Button> */}
          </CardActions>
        </Card>
      </Grid>


      {/* 
<Grid item lg={3} xs={12} >
<Card className={classes.card4}>
<CardContent>

<Typography className={classes.headingStyle} variant="h5"  color="primary" gutterBottom>
          55
        </Typography>
  <Typography className={classes.title} color="textSecondary" gutterBottom>
    NEW JOBS
  </Typography>
  
</CardContent>
<CardActions>
<RecentActors fontSize="large" className={classes.iconStyle} />

</CardActions>
</Card>
</Grid> */}


    </Grid>


  );
}